import { gql } from "@codesandbox/api";
import * as Sentry from "@sentry/browser";
import { env } from "environment-interface/env";
import { useCsbApi } from "hooks/api/useCsbApi";
import { useSession } from "hooks/api/useSession";
import {
  Box,
  Stack,
  Text,
  Collapsible,
  Button,
  useTheme,
  styled,
  keyframes,
} from "prism-react";
import type { RepositoryWorkspace } from "queries/project";
import React from "react";
import { useEditorServiceWorker } from "registerEditorServiceWorker";

import { useAnalytics } from "hooks/useAnalytics";
import { useEditorRouter } from "hooks/useEditorRouter";
import { useEditorUpdate } from "hooks/useEditorUpdate";

import { ErrorDetails } from "./common";
import { useGlobalErrorMatcher } from "./useGlobalErrorMatcher";

export type KnownErrors =
  | "UNKNOWN_ERROR"
  | "RUNTIME_ERROR"
  | "NO_WORKSPACE_ACCESS"
  | "NO_WORKSPACE_FOR_BRANCH_CREATION"
  | "REQUEST_WORKSPACE_ACCESS"
  | "REPOSITORY_NOT_FOUND"
  | "MICROVM_NOT_STARTED"
  | "SANDBOX_NOT_STARTED"
  | "UNAUTHENTICATED"
  | "RESTRICTED_REPOSITORY"
  | "PAGE_NOT_FOUND" // malformed url
  | "BRANCH_NOT_FOUND" // project not found
  | "SANDBOX_NOT_FOUND" // project not found
  | "DEVBOX_NOT_FOUND" // project not found
  | "ELIGIBLE_WORKSPACE_FOUND"
  | "WORKSPACE_FROZEN"
  | "LIVE_SESSION_STOPPED"
  | "LIVE_SESSION_NOT_FOUND"
  | "UNAUTHORIZED_PREVIEW";

export const GlobalError: React.FC<{
  /** Provide a knwon error code, which will render a proper cause and solution */
  errorCode: KnownErrors;

  /** Passed from the request workspace access flow */
  possibleWorkspacesToRequestAccess?: RepositoryWorkspace[];

  context: {
    /** Original error message */
    error: string;
    /** Provide any other context about this error */
    context?: string;
    /** What state was the application in when this error occured? */
    state?: string;
    /** Did this error occur when handling a specific event? */
    event?: string;
    /** In some instances, branchId is useful for debugging container problems */
    branchId?: string;

    originalError?: Error | unknown;
  };

  customCause?: {
    title: string;
    description: React.ReactNode;
    recoverButton?: React.ReactNode;
  };
}> = ({
  context,
  errorCode,
  possibleWorkspacesToRequestAccess,
  customCause,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const track = useAnalytics();
  const { theme } = useTheme();
  const editorUpdate = useEditorUpdate();
  const editorRouter = useEditorRouter();

  const isDarkTheme = theme.type === "dark";

  // The editor might not be loaded yet
  useEditorServiceWorker();

  if (
    errorCode === "NO_WORKSPACE_ACCESS" &&
    typeof window !== "undefined" &&
    window.location.search.includes("create=true")
  ) {
    errorCode = "NO_WORKSPACE_FOR_BRANCH_CREATION";
  }

  const [session] = useSession();

  const csbApi = useCsbApi();

  /**
   * If the user is authenticated and the content is not found (404)
   * we can try to infer the workspace of this user and auto-join them
   */
  const [eligibleWorkspace, setEligibleWorkspace] = React.useState<null | {
    id: string;
    name: string;
  }>();
  React.useEffect(
    function lookForEligibleWorkspace() {
      const contentNotFound = errorCode === "SANDBOX_NOT_FOUND";
      const isAuthenticated = session.state === "AUTHENTICATED";

      if (
        isAuthenticated &&
        contentNotFound &&
        editorRouter.sandbox?.urlParams
      ) {
        // Get short ids from url
        const sandboxId = editorRouter.sandbox.urlParams.id.split("-").pop()!;

        const sandboxEligibleWorkspace = gql.createQuery(
          "SandboxEligibleWorkspace",
          ({ sandboxId }: { sandboxId: string }) => ({
            sandboxEligibleWorkspace: [{ sandboxId }, { id: true, name: true }],
          }),
        );

        csbApi.gql
          .query(sandboxEligibleWorkspace, { sandboxId })
          .then(({ sandboxEligibleWorkspace }) => {
            if (sandboxEligibleWorkspace) {
              track("eligible_workspace_found");
              setEligibleWorkspace(sandboxEligibleWorkspace);
            }
          });
      }
    },
    [errorCode],
  );

  if (eligibleWorkspace) {
    errorCode = "ELIGIBLE_WORKSPACE_FOUND";
  }

  let cause = useGlobalErrorMatcher(
    errorCode,
    context.error,
    session.state === "AUTHENTICATED",
    possibleWorkspacesToRequestAccess,
    eligibleWorkspace,
  );

  cause = customCause || cause;

  const isAuthenticated = session.state === "AUTHENTICATED";
  const showErrorDetails =
    errorCode !== "LIVE_SESSION_STOPPED" &&
    errorCode !== "PAGE_NOT_FOUND" &&
    errorCode !== "REQUEST_WORKSPACE_ACCESS" &&
    errorCode !== "NO_WORKSPACE_ACCESS" &&
    errorCode !== "WORKSPACE_FROZEN";
  const showSupportCTA =
    errorCode !== "ELIGIBLE_WORKSPACE_FOUND" &&
    errorCode !== "LIVE_SESSION_NOT_FOUND" &&
    errorCode !== "LIVE_SESSION_STOPPED" &&
    errorCode !== "REQUEST_WORKSPACE_ACCESS" &&
    errorCode !== "NO_WORKSPACE_ACCESS" &&
    errorCode !== "WORKSPACE_FROZEN";

  React.useEffect(() => {
    if (!cause) return;
    document.title = cause.title;

    track("error_page", {
      description: errorCode ?? "UNKNOWN_ERROR",
      error: context.error,
      event: context.event,
      context: context.context,
      event_source: "editor",
    });

    if (context.originalError instanceof Error) {
      Sentry.captureException(context.originalError, {
        tags: {
          context: context.context,
          event: context.event,
          description: errorCode ?? "UNKNOWN_ERROR",
        },
      });
    } else {
      Sentry.captureException(context.error, {
        tags: {
          context: context.context,
          event: context.event,
          description: errorCode ?? "UNKNOWN_ERROR",
        },
      });
    }
  }, []);

  let recoveryButton = cause.recoverButton;
  if (editorUpdate?.status === "EDITOR_CHECKING_VERSION") {
    recoveryButton = (
      <Button variant="primary" disabled>
        Checking version...
      </Button>
    );
  } else if (editorUpdate?.status === "EDITOR_AND_MICRO_VM_UPDATED") {
    recoveryButton = (
      <Button icon="refresh" onClick={editorUpdate.restart} variant="primary">
        Update to latest editor and microVM
      </Button>
    );
  } else if (editorUpdate?.status === "EDITOR_UPDATED") {
    recoveryButton = (
      <Button icon="refresh" onClick={editorUpdate.restart} variant="primary">
        Update to latest editor
      </Button>
    );
  } else if (editorUpdate?.status === "MICRO_VM_UPDATED") {
    recoveryButton = (
      <Button icon="refresh" onClick={editorUpdate.restart} variant="primary">
        Update to latest microVM
      </Button>
    );
  } else if (editorUpdate?.status === "EDITOR_UPDATE_ERROR") {
    recoveryButton = (
      <Button icon="refresh" onClick={editorUpdate.restart} variant="primary">
        Clear cache and reload
      </Button>
    );
  }

  return (
    <Box
      css={{
        height: "100vh",
        backgroundColor: isDarkTheme ? "$neutral-bg-low" : "$neutral-bg-base",
        lineHeight: "140%",
      }}
      selectable
    >
      <Stack
        css={{
          height: "100%",
          padding: "10vw",
          position: "relative",
          zIndex: 9,
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Box
          as="a"
          css={{
            color: "$neutral-fg-high",
            width: 200,
            paddingBottom: "5vw",
            marginLeft: -12,
          }}
          href="/"
        >
          <CodeSandbox />
        </Box>

        <Stack
          css={{ "@media screen and (min-width: 1024px)": { maxWidth: 528 } }}
          gap={6}
          selectable
        >
          {isAuthenticated && (
            <Button
              as="a"
              css={{ width: "fit-content" }}
              href="/dashboard"
              icon="arrowBack"
              iconPosition="left"
              variant="ghost"
            >
              Back to the dashboard
            </Button>
          )}

          <Text as="h1" color="neutral-fg-high" size="lg" weight="semibold">
            {cause.title}
          </Text>
          <Text as="div">{cause.description}</Text>

          {showSupportCTA && (
            <Text as="p">
              If the issue persists,{" "}
              <strong>please contact our support team at</strong>{" "}
              <Text
                as="a"
                color="neutral-fg-high"
                href="mailto:support@codesandbox.io"
              >
                support@codesandbox.io
              </Text>{" "}
              for further assistance, making sure to copy the error details
              below.
            </Text>
          )}

          {showErrorDetails && (
            <Collapsible onOpenChange={setIsOpen} open={isOpen}>
              <Collapsible.Trigger open={isOpen}>
                <Text>Problem details and configurations</Text>
              </Collapsible.Trigger>
              <Collapsible.Content>
                <ErrorDetails
                  error={context.error}
                  versions={[
                    ["Context", context.context],
                    ["Event", context.event],
                    ["State", context.state],
                    ["Branch", context.branchId],
                  ]}
                />
              </Collapsible.Content>
            </Collapsible>
          )}

          <Stack css={{ flexShrink: 0 }} gap={3} horizontal>
            {recoveryButton}

            {showErrorDetails && (
              <Button onClick={() => setIsOpen((p) => !p)} variant="secondary">
                {isOpen ? "Hide details" : "Show details"}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Scene
        css={{
          $bg: isDarkTheme ? "$neutral-bg-low" : "$neutral-bg-base",
        }}
      >
        <Fade />
        <Canvas
          style={{
            WebkitMask: `radial-gradient(circle at 50% 50%, white calc(var(--dot) * 1px), transparent calc((var(--dot) * 1px) + 0.5px)) 50% 50% / calc(var(--spread) * 1px) calc(var(--spread) * 1px),
            url("${
              env.PUBLIC_BASE_PATH || ""
            }/imgs/noiseTexture.png") calc(var(--size) * 1px) 50% / calc(var(--size) * 1px) calc(var(--size) * 1px)`,
            WebkitMaskComposite: "source-in, xor",
            maskComposite: "intersect",
          }}
        />
      </Scene>
    </Box>
  );
};

const Scene = styled("div", {
  $canvabg:
    "linear-gradient(45deg, var(--colors-accent-primary-base), var(--colors-accent-secondary-high))",
  $spread: "50",
  $dot: "1",
  $size: "1000",
  $speed: "50",

  background: "var(--bg)",
  position: "fixed",
  top: 0,
  bottom: 0,
  right: 0,
  width: "70vw",
});

const Canvas = styled("div", {
  position: "absolute",
  inset: 0,
  background: "var(--canvabg)",

  animation: `${keyframes({
    "100%": {
      maskPosition: `50% 50%, 0 50%`,
      WebkitMaskPosition: `50% 50%, 0 50%`,
    },
  })} calc(var(--speed) * 1s) infinite linear`,
});

const Fade = styled("div", {
  position: "absolute",
  inset: 0,
  background: "linear-gradient(120deg, var(--bg), transparent)",
  zIndex: 10,
});

const CodeSandbox: React.FC = (props) => (
  <svg
    fill="none"
    viewBox="0 0 2355 600"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M150 150h299.832v300H150V150Zm269.168 30.682v238.636H180.665V180.682h238.503Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M698.069 326.409h-32.295c-4.692 29.275-24.29 46.122-51.34 46.122-35.883 0-59.346-27.894-59.346-72.359 0-43.912 22.634-72.359 57.414-72.359 28.154 0 46.648 16.571 51.892 46.398h32.295c-6.348-43.636-41.403-72.083-85.292-72.083-52.72 0-89.156 43.637-89.156 98.044 0 56.065 37.264 98.044 89.985 98.044 43.888 0 79.219-28.17 85.843-71.807Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M846.258 326.409c0-37.284-25.67-71.807-67.626-71.807s-67.626 34.523-67.626 71.807 25.67 71.807 67.626 71.807 67.626-34.523 67.626-71.807Zm-103.509 0c0-29.551 12.973-49.436 35.883-49.436 23.186 0 36.159 19.885 36.159 49.436 0 29.275-12.973 49.436-36.159 49.436-22.91 0-35.883-20.161-35.883-49.436ZM963.668 366.455h-.828c-6.624 19.057-22.634 31.761-43.612 31.761-37.539 0-57.965-33.142-57.965-71.807s21.254-71.807 57.965-71.807c20.978 0 36.988 12.429 43.612 31.485h.828V204.89h29.535v190.564h-29.535v-28.999Zm-34.779-89.482c-22.91 0-35.883 19.885-35.883 49.436 0 29.275 12.973 49.436 35.883 49.436 23.186 0 35.883-20.161 35.883-49.436 0-29.551-12.697-49.436-35.883-49.436ZM1141.69 352.646h-30.08c-3.32 11.047-11.04 23.199-28.99 23.199-20.97 0-35.6-14.637-36.98-39.77h98.54c4.14-48.055-22.91-81.473-64.04-81.473-41.13 0-64.59 33.694-64.59 71.807s24.01 71.807 65.14 71.807c33.68 0 55.21-18.228 61-45.57Zm-61.55-75.673c20.15 0 32.02 14.361 33.95 36.732h-68.18c1.94-22.371 14.08-36.732 34.23-36.732Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M1260.65 285.811c35.88 8.285 53.82 27.065 53.82 57.169 0 31.761-30.91 55.236-75.08 55.236-50.51 0-77.84-35.351-80.87-69.874h32.29c1.93 24.857 22.08 44.189 48.86 44.189 24.29 0 42.23-10.495 42.23-28.17 0-15.466-11.59-23.752-36.16-29.551l-27.6-6.353c-37.27-8.837-55.76-26.237-55.76-52.75 0-32.037 31.47-53.579 69.83-53.579 48.59 0 73.43 26.514 76.74 59.379h-32.29c-2.49-22.647-21.54-33.694-43.89-33.694-21.81 0-37.54 9.39-37.54 25.685 0 12.98 9.66 19.056 37.81 25.684l27.61 6.629Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M1430.57 366.455h.83v28.999h29.54v-138.09h-29.54v28.723h-.83c-6.62-19.056-22.63-31.485-43.61-31.485-36.71 0-57.96 33.142-57.96 71.807s20.42 71.807 57.96 71.807c20.98 0 36.99-12.704 43.61-31.761Zm-69.83-40.046c0-29.551 13.25-49.436 35.61-49.436 22.35 0 36.16 19.885 36.16 49.436 0 29.275-13.81 49.436-36.16 49.436-22.09 0-35.61-20.161-35.61-49.436Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M1521.1 312.6v82.854h-30.09v-138.09h29.26v27.066h.55c5.8-19.333 20.15-29.828 40.3-29.828 30.37 0 45.27 20.714 45.27 51.094v89.758h-30.09v-80.921c0-25.684-9.1-37.284-27.6-37.284-18.49 0-27.6 12.704-27.6 35.351Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M1729.46 366.455h.83v28.999h29.53V204.89h-29.53v81.197h-.83c-6.63-19.056-22.64-31.485-43.61-31.485-36.72 0-57.97 33.142-57.97 71.807s20.43 71.807 57.97 71.807c20.97 0 36.98-12.704 43.61-31.761Zm-69.84-40.046c0-29.551 12.98-49.436 35.89-49.436 23.18 0 35.88 19.885 35.88 49.436 0 29.275-12.7 49.436-35.88 49.436-22.91 0-35.89-20.161-35.89-49.436ZM1863.87 254.602c36.71 0 57.97 33.142 57.97 71.807s-20.43 71.807-57.97 71.807c-20.98 0-36.99-12.704-43.61-31.761h-.83v28.999h-29.53V204.89h29.53v81.197h.83c6.62-19.056 22.63-31.485 43.61-31.485Zm-9.66 22.371c-23.19 0-35.88 19.885-35.88 49.436 0 29.275 12.69 49.436 35.88 49.436 22.91 0 35.88-20.161 35.88-49.436 0-29.551-12.97-49.436-35.88-49.436ZM2072.25 326.409c0-37.284-25.67-71.807-67.62-71.807-41.96 0-67.63 34.523-67.63 71.807s25.67 71.807 67.63 71.807c41.95 0 67.62-34.523 67.62-71.807Zm-103.51 0c0-29.551 12.98-49.436 35.89-49.436 23.18 0 36.16 19.885 36.16 49.436 0 29.275-12.98 49.436-36.16 49.436-22.91 0-35.89-20.161-35.89-49.436Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="m2139.61 341.875-36.71 53.579h-33.95l53-74.292-46.1-63.798h33.95l29.81 43.084 29.81-43.084h33.68l-45.82 63.522 52.72 74.568h-33.68l-36.71-53.579Z"
      fill="currentColor"
    />
  </svg>
);
