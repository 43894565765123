import type { IFileSystemProviderWithFileReadWriteCapability } from "@codingame/monaco-vscode-files-service-override";
import { jsonc } from "jsonc";
import { onGrid } from "prism-react";
import { getColorSchemaFromStorage } from "prism-react/theme/utils";
import type * as vscode from "vscode";

export function getDefaultUserSettings(
  isBrowserSandboxEnvironment: boolean,
): Record<string, unknown> {
  const colorTheme = getColorSchemaFromStorage();

  return {
    "editor.automaticLayout": false,
    "editor.scrollbar.useShadows": false,
    "editor.scrollbar.verticalScrollbarSize": onGrid(1.5),
    "editor.scrollbar.horizontalScrollbarSize": onGrid(1.5),
    "editor.minimap.enabled": false,
    "editor.minimap.renderCharacters": false,
    "editor.guides.indentation": false,
    "editor.renderLineHighlightOnlyWhenFocus": true,
    "editor.overviewRulerBorder": false,
    "editor.fixedOverflowWidgets": true,
    "editor.fontSize": 14,
    "editor.lineHeight": 0, // Use 0 to automatically compute the line height from the font size.
    "editor.lineNumbersMinChars": 5,

    "editor.formatOnSave": true,
    ...(isBrowserSandboxEnvironment
      ? { "editor.defaultFormatter": "esbenp.prettier-vscode" }
      : {}),

    "editor.quickSuggestions": true,
    "editor.quickSuggestionsDelay": 10,
    "editor.suggestOnTriggerCharacters": true,
    "editor.acceptSuggestionOnCommitCharacter": false,
    "editor.wrappingIndent": "same",
    "workbench.colorTheme": colorTheme.name,

    "files.autoSave": "onFocusChange",
  };
}

type KeyBinding = { key: string; command: string };

export function getDefaultKeybindings(): KeyBinding[] {
  return [
    {
      key: "cmd+j",
      command: "-workbench.action.togglePanel",
    },
    {
      key: "cmd+k",
      command: "workbench.action.showCommands",
    },
    {
      key: "cmd+b",
      command: "-workbench.action.toggleSidebarVisibility",
    },
  ];
}

const MARKERS = ["// CSB-START", "// CSB-END"] as const;

export async function appendKeyBindings(
  provider: IFileSystemProviderWithFileReadWriteCapability,
  resource: vscode.Uri,
  bindings: KeyBinding[],
) {
  try {
    const file = await provider.readFile(resource);
    if (!file) {
      // We use the default bindings as the starting point
      throw new Error("no file to append to");
    }

    let fileContent = new TextDecoder().decode(file);

    // Check if there's a CSB comment in there
    if (fileContent.includes(MARKERS[0])) {
      // Replace everything between CSB-START and CSB-END with the new bindings
      const startIndex = fileContent.indexOf(MARKERS[0]);
      const endIndex = fileContent.indexOf(MARKERS[1]);
      const contentToWrite = `${
        MARKERS[0]
      }\n  // These are keybindings added by CodeSandbox to integrate VSCode with its UI\n${JSON.stringify(
        bindings,
        null,
        2,
      )
        // Remove first [ and last ], as we're injecting inside an array
        .slice(1, -1)}\n  ${MARKERS[1]}`;

      fileContent =
        fileContent.slice(0, startIndex) +
        contentToWrite +
        fileContent.slice(endIndex + MARKERS[1].length);
    } else {
      const fileBindings = jsonc.parse(fileContent) as KeyBinding[];
      fileContent = jsonc.stringify(
        [
          MARKERS[0],
          ...bindings,
          MARKERS[1],
          ...fileBindings.filter(
            (fileBinding) => !bindings.some((b) => b.key === fileBinding.key),
          ),
        ],
        undefined,
        2,
      );

      fileContent = fileContent
        .replace(`"${MARKERS[0]}",`, `${MARKERS[0]}`)
        .replace(`'${MARKERS[0]}',`, `${MARKERS[0]}`)
        .replace(`"${MARKERS[1]}",`, `${MARKERS[1]}`)
        .replace(`'${MARKERS[1]}',`, `${MARKERS[1]}`);
    }

    await provider.writeFile(resource, new TextEncoder().encode(fileContent), {
      create: true,
      overwrite: true, // For some reason the `overwrite` flag has no effect.
      atomic: false,
      unlock: false,
    });
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
}
