import { KBarSearch } from "@codesandbox/kbar";
import React from "react";

import { styled } from "../../theme";
import { Icon } from "../Icon";
import { Stack } from "../Stack";

import { useCommandPalette } from "./hooks";
import { useGroup } from "./useGroup";

const Back = styled("button", {
  flexGrow: 0,
  background: "$neutral-bg-focus",
  width: "$5",
  height: "$5",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  border: "none",
  color: "$neutral-fg-subtle",

  ":hover": {
    color: "$neutral-fg-high",
  },
});

const StyledSearch = styled(KBarSearch, {
  background: "transparent",
  border: "none",
  outline: "none",
  color: "$neutral-fg-high",
  width: "100%",
  lineHeight: 1,
});

interface Props {
  placeholder?: string;
}

export const Search: React.FC<Props> = ({
  placeholder = "Type a command or search...",
}) => {
  const { query } = useCommandPalette();
  const group = useGroup();
  const isGroup = group.state === "GROUP";

  return (
    <Stack css={{ justifyContent: "flex-start", padding: "$4" }} horizontal>
      {isGroup && (
        <Back
          onClick={() => query.setCurrentRootAction(group.parent || undefined)}
        >
          <Icon size={3} type="chevronLeft" />
        </Back>
      )}
      <StyledSearch
        /**
          Hack: We want to customize the placeholder for each group, but don't have a clean way to do this.
          As a workaround, we use the subtitle property.
        */
        placeholder={isGroup ? group.subtitle || placeholder : placeholder}
        autoFocus
      />
    </Stack>
  );
};
