import { DisposableStore } from "@codesandbox/pitcher-common";
import type { IEditorOptions } from "@codingame/monaco-vscode-editor-service-override";
import type * as monaco from "monaco-editor";
import type * as vscode from "vscode";
import type {
  EditorInput,
  IEditorGroup,
  IInstantiationService,
} from "vscode/services";
import { createInstance } from "vscode/services";
import { ThemeIcon } from "vscode/vscode/vs/base/common/themables";
import type {
  IEditorOpenContext,
  IEditorSerializer,
} from "vscode/vscode/vs/workbench/common/editor";
import type { IEditorCloseHandler } from "vscode/vscode/vs/workbench/common/editor/editorInput";

export type RegisterEditorParams = {
  id: string;
  name: string;
  getIcon?: (resource: vscode.Uri) => string | undefined;
  globPattern?: string;
  paneLabel?: string;
  onRender: (
    element: HTMLElement,
    input: EditorInput,
  ) => {
    dispose: () => void;
    setInput: (
      input: EditorInput,
      options: IEditorOptions | undefined,
      context: IEditorOpenContext,
      token: vscode.CancellationToken,
    ) => Promise<void>;
  };
  serializer?: {
    serialize(editor: EditorInput): string | undefined;
    deserialize(
      instantiationService: IInstantiationService,
      serializedEditor: string,
    ): vscode.Uri | undefined;
  };
};

export async function createRegisterEditor(mode: "workbench" | "integrated") {
  const api = await (mode === "workbench"
    ? import("@codingame/monaco-vscode-workbench-service-override")
    : import("@codingame/monaco-vscode-views-service-override"));

  return function registerEditor({
    id,
    name,
    onRender,
    globPattern,
    getIcon,
    serializer,
    paneLabel,
  }: RegisterEditorParams) {
    const disposableStore = new DisposableStore();

    class CustomEditorPane extends api.SimpleEditorPane {
      static readonly ID = id;

      constructor(editorGroup: IEditorGroup) {
        super(CustomEditorPane.ID, editorGroup);
      }

      initialize(): HTMLElement {
        const container = document.createElement("div");
        container.style.display = "flex";
        container.style.minHeight = "100%";
        container.style.minWidth = "100%";
        return container;
      }

      async renderInput(input: EditorInput): Promise<monaco.IDisposable> {
        return onRender(this.container, input);
      }

      setInput(
        input: EditorInput,
        editorOptions: IEditorOptions | undefined,
        context: IEditorOpenContext,
        token: vscode.CancellationToken,
      ): Promise<void> {
        return super.setInput(input, editorOptions, context, token);
      }
    }

    // @ts-expect-error same class different files
    class CustomEditorInput
      extends api.SimpleEditorInput
      implements IEditorCloseHandler
    {
      constructor(resource: vscode.Uri) {
        super(resource);

        // @ts-expect-error same class different files
        this.closeHandler = this;
      }

      showConfirm(): boolean {
        return false;
      }

      getIcon() {
        if (!this.resource) {
          return undefined;
        }

        const icon = getIcon && getIcon(this.resource);
        if (icon) {
          return ThemeIcon.fromString(icon);
        } else {
          return undefined;
        }
      }

      get typeId(): string {
        return CustomEditorPane.ID;
      }

      get editorId(): string {
        return CustomEditorPane.ID;
      }
    }

    disposableStore.add(
      api.registerEditorPane(id, name, CustomEditorPane, [CustomEditorInput]),
    );

    if (globPattern) {
      const resolveDisposable = api.registerEditor(
        globPattern,
        {
          id: CustomEditorPane.ID,
          label: paneLabel ?? "CodeSandbox Panes",
          priority: api.RegisteredEditorPriority.default,
        },
        {
          singlePerResource: true,
        },
        {
          async createEditorInput(editorInput) {
            return {
              editor: await createInstance(
                CustomEditorInput,
                editorInput.resource,
              ),
            };
          },
        },
      );

      disposableStore.add(resolveDisposable);
    }

    if (serializer) {
      const serializerDisposable = api.registerEditorSerializer(
        CustomEditorPane.ID,
        class CustomSerializer implements IEditorSerializer {
          canSerialize(): boolean {
            return true;
          }

          serialize(editor: EditorInput): string | undefined {
            return serializer.serialize(editor);
          }

          deserialize(
            instantiationService: IInstantiationService,
            serializedEditor: string,
          ): EditorInput | undefined {
            const resource = serializer.deserialize(
              instantiationService,
              serializedEditor,
            );

            if (!resource) {
              return;
            }

            return instantiationService.createInstance(
              CustomEditorInput,
              resource,
            );
          }
        },
      );

      disposableStore.add(serializerDisposable);
    }

    return disposableStore;
  };
}
