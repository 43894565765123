import { useStateTransition } from "@codesandbox/states";
import { useEnvironmentInterface } from "environment-interface";
import { useCsbApi } from "hooks/api/useCsbApi";
import { useSession } from "hooks/api/useSession";
import { inferProjectForBranch } from "isomorphic/queries";
import { createBranch } from "queries/project";
import React, { useEffect, useReducer, useRef } from "react";
import * as vscode from "vscode";

import { useActiveWorkspace } from "hooks/useActiveWorkspace";
import { useAnalytics } from "hooks/useAnalytics";
import { useEditorRouter } from "hooks/useEditorRouter";
import { createHookContext } from "utils/createHookContext";
import { paths } from "utils/router";

import { useEditorFeature } from "../editor/Feature";
import {
  useAddAlertNotification,
  useAddStatusNotification,
} from "../notifications/hooks";
import { toSentryContext } from "../utils/sentry";
import { useLegacyPitcher, usePitcher } from "../utils/usePitcher";
import { useReducerDevtools } from "../utils/useReducerDevtools";

import { reducer } from "./reducer";
import type { State } from "./types";

const HAS_EXPERIENCED_SEAMLESS_BRANCHING_STORAGE_KEY =
  "CSB_HAS_EXPERIENCED_SEAMLESS_BRANCHING";

interface Props {
  branchId: string;
  initialState?: State;
}

export const useContainer = createHookContext(
  ({ branchId, initialState }: Props) => {
    const legacyPitcher = useLegacyPitcher();
    const pitcher = usePitcher();
    const csbApi = useCsbApi();
    const [session] = useSession();
    const router = useEditorRouter();

    const [editorFeature, dispatchEditor] = useEditorFeature();

    const activeWorkspace = useActiveWorkspace();
    const environment = useEnvironmentInterface();
    const track = useAnalytics();
    const addAlertNotification = useAddAlertNotification();
    const addStatusNotification = useAddStatusNotification();
    const hasFetchedVersionsRef = React.useRef(false);
    const isCreatingBranchRef = useRef(false);

    function getInitialState(): State {
      return (
        initialState || {
          state: "READY",
          cluster: pitcher.cluster,
          versions: {
            pitcher: pitcher.pitcherVersion,
            pitcherManager: pitcher.pitcherManagerVersion,
          },
          startedAt: Date.now(),
        }
      );
    }

    const containerReducer = useReducer(reducer, getInitialState());

    useReducerDevtools("container", containerReducer);

    const [state, dispatch] = containerReducer;

    // Subscribe to Pitcher disconnect and track any late pong response and ensure user
    // is blocked from refreshing
    useEffect(() => {
      // Crappy hack with legacy layer, need to check this explicitly as it can be an empty object
      if (!pitcher.onStateChange) {
        return;
      }

      const evaluateUnloadWarning = (
        state: ReturnType<typeof pitcher.state.get>,
      ) => {
        if (state.state === "DISCONNECTED") {
          window.onbeforeunload = () => {
            return "You are disconnected, are you sure you want to reload. You risk loosing changes";
          };
          if (state.reason.toLowerCase().includes("pong")) {
            track("pitcher_late_pong");
          }
        } else {
          window.onbeforeunload = () => {};
        }
      };

      evaluateUnloadWarning(pitcher.state.get());

      return pitcher.onStateChange(evaluateUnloadWarning);
    }, [pitcher]);

    useEffect(() => {
      if (state.state === "READY" && editorFeature.state === "READY") {
        const createSeamlessBranch = async (targetBranch?: string) => {
          if (isCreatingBranchRef.current) {
            return;
          }

          track("fork", {
            event_source: "seamless",
            target: "default",
            target_branch: targetBranch,
          });

          dispatchEditor({
            type: "START_SEAMLESS_BRANCH",
          });

          isCreatingBranchRef.current = true;
          try {
            if (session.state === "UNAUTHENTICATED") {
              dispatchEditor({
                type: "SET_PREVENT_AUTHENTICATION_REFETCH",
                prevent: true,
              });

              const user = await csbApi.session.signIn();

              dispatchEditor({
                type: "SET_PREVENT_AUTHENTICATION_REFETCH",
                prevent: false,
              });

              if (!user) {
                throw new Error("Can not seamless branch without a user");
              }

              // When you sign in on a branch we need to first check if the user should actually be on a different workspace
              if (editorFeature.editor.type === "branch") {
                const inferredWorkspace = await inferProjectForBranch(csbApi, {
                  branch: editorFeature.editor.branch,
                  owner: editorFeature.editor.owner,
                  repo: editorFeature.editor.repo,
                  workspaceId: null,
                });

                // If we infer that you should be on a different workspace, we give a message and then reload the page, because we need
                // to seamlessly continue from the VM that we should actually fork from
                if (inferredWorkspace?.team) {
                  addAlertNotification({
                    message:
                      "After signing in we noticed this branch is available on a workspace. Please reload the page to continue.",
                    actions: [
                      {
                        text: "Reload",
                        onAction: () => window.location.reload(),
                      },
                    ],
                    showDismiss: false,
                  });

                  return;
                }
              }
            }

            if (editorFeature.editor.type === "sandbox") {
              environment.api.createSeamlessSandboxFork(
                editorFeature.editor.alias,
                editorFeature.editor.isCloud,
                activeWorkspace?.id,
              );
            } else if (editorFeature.editor.workspace) {
              environment.api.createSeamlessBranch({
                owner: editorFeature.editor.owner,
                repo: editorFeature.editor.repo,
                sourceBranch: editorFeature.editor.branch,
                workspaceId: editorFeature.editor.workspace.id,
                targetBranch,
              });
            } else {
              environment.api.createSeamlessContributionBranch(
                editorFeature.editor.owner,
                editorFeature.editor.repo,
                editorFeature.editor.branch,
              );
            }
          } catch {
            dispatchEditor({
              type: "SET_PREVENT_AUTHENTICATION_REFETCH",
              prevent: false,
            });
            isCreatingBranchRef.current = false;
          }
        };

        const checkoutPreventedDisposer =
          pitcher.clients.git.onCheckoutPrevented(async (branch) => {
            if (
              editorFeature.editor.type === "branch" &&
              editorFeature.editor.workspace
            ) {
              try {
                await createBranch({
                  owner: editorFeature.editor.owner,
                  repo: editorFeature.editor.repo,
                  sourceBranch: editorFeature.editor.branch,
                  branch,
                  workspaceId: editorFeature.editor.workspace.id,
                });
              } catch {
                // We do not care if it fails, we'll just try navigating there and hope for the best
              }

              router.branch.goToBranch({
                owner: editorFeature.editor.owner,
                repo: editorFeature.editor.repo,
                branch: branch,
                workspaceId: editorFeature.editor.workspace?.id ?? null,
                checkout: "true",
              });

              track("branch_checkout");

              addStatusNotification({
                message: `Checkout to "${branch}" branch. All branches in CodeSandbox has their own MicroVM and environment.`,
                status: "success",
                icon: "branch",
                timeout: 10_000,
                actions: [
                  {
                    text: "Learn more",
                    onAction: () => {
                      track("learn_more_branch_checkout");
                      window.open(
                        "https://codesandbox.io/docs/learn/repositories/getting-started/git-workflow#terminal",
                        "_blank",
                      );
                    },
                  },
                ],
              });
            }
          });
        const disposable = pitcher.onInstanceChangeRequired((requestMethod) => {
          switch (requestMethod) {
            case "file/documentOperation": {
              // We do not show the sign in modal on immediate code change as it is quite jarring. Also if the user cancels
              // the sign in we do not trigger it again, as operations are now being queued, not triggering this logic
              if (session.state === "UNAUTHENTICATED") {
                return;
              }

              createSeamlessBranch();
              return;
            }
            default: {
              createSeamlessBranch();
            }
          }
        });
        return () => {
          disposable.dispose();
          checkoutPreventedDisposer.dispose();
        };
      }
    }, [pitcher, editorFeature, state.state, session.state, activeWorkspace]);

    useStateTransition(
      state,
      {
        READY: "API:CREATE_SEAMLESS_INSTANCE_SUCCESS",
      },
      ({ data }) => {
        // This will emit an event which actually changes the branch
        environment.pitcherInstanceManager.changeInstance(branchId, data);
      },
    );

    useStateTransition(
      state,
      {
        READY: "API:CREATE_SEAMLESS_INSTANCE_ERROR",
      },
      ({ error }) => {
        let errorMessage = error;

        // Revert local changes
        vscode.commands.executeCommand("workbench.action.files.revert");

        if (error.includes("DRAFT_LIMIT") || error.includes("SANDBOX_LIMIT")) {
          errorMessage =
            "We could not fork it to your Drafts folder, because it is full";

          track("seamless_fork_sandbox_limit");
        }

        const workspaceId =
          editorFeature.state === "READY" && editorFeature.editor.workspace
            ? editorFeature.editor.workspace.id
            : undefined;

        addStatusNotification({
          status: "error",
          message: errorMessage,
          actions: [
            {
              text: "Open drafts",
              onAction: () => {
                window.open(paths.dashboard.drafts(workspaceId), "_blank");
              },
            },
          ],
          timeout: 10_000,
        });

        // Finish up seamless forking
        environment.pitcherInstanceManager.revertSeamlessFork();
        isCreatingBranchRef.current = false;

        dispatchEditor({
          type: "REVERT_SEAMLESS_BRANCH",
        });
      },
    );

    useStateTransition(
      state,
      {
        READY: "PITCHER:CONNECTION_CHANGED_SUCCESS",
      },
      ({ data }) => {
        dispatchEditor({
          type: "SWITCH_SEAMLESS_INSTANCE",
          data,
        });

        const hasDirtyFiles = Boolean(
          pitcher.clients.file
            .getOpenFiles()
            .find((file) => file.object.isDirty),
        );

        const triggerNotification = () => {
          const hasExperiencedSeamlessBranching =
            environment.storage.get<boolean>(
              HAS_EXPERIENCED_SEAMLESS_BRANCHING_STORAGE_KEY,
            );

          if (editorFeature.state !== "READY") {
            return;
          }

          if (editorFeature.editor.type === "sandbox") {
            const label =
              editorFeature.editor.isCloud === true ? "Devbox" : "Sandbox";

            addStatusNotification({
              message: `We forked this ${label} to your Drafts, so that you can edit it`,
              status: "success",
            });

            return;
          }

          if (data.type === "branch") {
            if (hasExperiencedSeamlessBranching) {
              addStatusNotification({
                message: (
                  <>
                    Created new branch <strong>{data.data.name}</strong>
                  </>
                ),
                status: "success",
              });
            } else {
              environment.storage.set(
                HAS_EXPERIENCED_SEAMLESS_BRANCHING_STORAGE_KEY,
                true,
              );
              addAlertNotification({
                message: (
                  <>
                    We just created the new branch{" "}
                    <strong>{data.data.name}</strong> so you can keep coding!
                  </>
                ),
                actions: [
                  {
                    onAction: () => {
                      window.open(
                        "https://codesandbox.io/docs/learn/getting-started/setting-up-repository#branching-workflow",
                      );
                    },
                    text: "Learn more",
                  },
                ],
              });
            }
          }
        };

        if (hasDirtyFiles) {
          const disposer = pitcher.clients.file.onFileSave(() => {
            disposer.dispose();
            triggerNotification();
          });
        } else {
          triggerNotification();
        }
      },
    );

    // Pitcher events need to be resubscribed when the container is retarted/disconnected/reconnected
    useEffect(
      () => legacyPitcher.events.subscribe(dispatch),
      [legacyPitcher.events],
    );

    useEffect(() => environment.api.events.subscribe(dispatch), []);
    useEffect(() => environment.visibility.events.subscribe(dispatch), []);

    useStateTransition(state, "RESTARTING", () => {
      pitcher.disconnect();
      environment.pitcherInstanceManager.stopPitcherEnvironment(branchId);
    });

    useStateTransition(state, "READY", () => {
      if (!hasFetchedVersionsRef.current) {
        /**
         * TODO: Expose /versions endpoint through the rest api, currently this fetch returns an empty html page
         * https://linear.app/codesandbox/issue/CSB-4324/expose-legacyPitcher-manager-versions-call-from-the-rest-api
         * Once we have this in the rest api, we can reactivate the fetch and render the restart notification
         */
        // legacyPitcher.versions.fetch();
        hasFetchedVersionsRef.current = true;
      }

      if (state.state === "READY" && state.cluster) {
        environment.sentry.setTag("cluster", state.cluster);
      }
    });

    useStateTransition(
      state,
      {
        READY: "PITCHER:CONNECTION_CHANGED_ERROR",
      },
      ({ error }) => {
        environment.sentry.reportError(error);
        addStatusNotification({
          message: "Branch cannot be created. Please try again later.",
          status: "error",
        });
      },
    );

    useStateTransition(
      editorFeature,
      "RENAMING_BRANCH",
      ({ newName, editor }) => {
        if (editor.type !== "branch") {
          return;
        }

        legacyPitcher.renameBranch({
          newName,
          oldName: editor.branch,
          branchId: editor.id,
        });
      },
    );

    useStateTransition(
      state,
      { READY: { "PITCHER:SET_BRANCH_NAME": "READY" } },
      (_, { name }) => {
        dispatchEditor({
          type: "SET_BRANCH_NAME",
          name,
        });
      },
    );

    useStateTransition(
      state,
      { READY: { "PITCHER:BRANCH_RENAME_FINISHED": "READY" } },
      () => {
        dispatchEditor({
          type: "BRANCH_RENAME_FINISHED",
        });
      },
    );

    useStateTransition(
      state,
      { READY: { "PITCHER:BRANCH_RENAME_ERROR": "READY" } },
      (_, { error }) => {
        dispatchEditor({
          type: "BRANCH_RENAME_ERROR",
          error,
        });
      },
    );

    useStateTransition(
      state,
      { READY: { "PITCHER:BRANCH_RENAME_TAKEN_ERROR": "READY" } },
      (_, { nameTaken }) => {
        dispatchEditor({
          type: "BRANCH_RENAME_TAKEN_ERROR",
          nameTaken,
        });
      },
    );

    useStateTransition(
      state,
      { READY: { "PITCHER:CLIENTS:CLIENT_PERMISSIONS_UPDATED": "READY" } },
      (_, { permissions, isProtected }) => {
        dispatchEditor({
          type: "PITCHER:CLIENTS:CLIENT_PERMISSIONS_UPDATED",
          permissions,
          isProtected,
        });
      },
    );

    useStateTransition(state, "ERROR", ({ error, context }) => {
      environment.sentry.reportError(
        error,
        toSentryContext("container", {
          ...context,
          branchId,
        }),
      );
    });

    return containerReducer;
  },
);

export const ContainerProvider = useContainer.Provider;

export const ContainerConsumer = useContainer.Consumer;
