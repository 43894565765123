import { gql } from "@codesandbox/api";
import { useEnvironmentInterface } from "environment-interface";
import { useContainer } from "features/container/Feature";
import { useEditor } from "features/editor/Feature";
import { useSession } from "hooks/api/useSession";
import { Box, Button, Tooltip, Stack, Text, Avatar, Select } from "prism-react";
import type { RepositoryWorkspace } from "queries/project";
import * as React from "react";

import { useAnalytics } from "hooks/useAnalytics";
import { useGitHubScopes } from "hooks/useGitHubScopes";
import { csbApi } from "utils/csbApi";

import type { KnownErrors } from "./GlobalError";

export const useGlobalErrorMatcher = (
  errorCode: KnownErrors,
  originalError: string,
  isAuthenticated: boolean,
  possibleWorkspacesToRequestAccess?: RepositoryWorkspace[],
  eligibleWorkspace?: { id: string; name: string } | null,
): {
  title: string;
  description: React.ReactNode;
  /** A button to give users a way to attempt to recover from this error */
  recoverButton?: React.ReactNode;
} => {
  switch (errorCode) {
    case "ELIGIBLE_WORKSPACE_FOUND": {
      return {
        title: "Permission denied",
        description: (
          <>
            It looks like you are trying to open a private Sandbox. Your email
            matches the domain of the {eligibleWorkspace?.name} workspace.{" "}
            <br />
            <br />
            Would you like to join the {eligibleWorkspace?.name} workspace?
          </>
        ),
        recoverButton: (
          <JoinWorkspaceButton eligibleWorkspaceId={eligibleWorkspace?.id} />
        ),
      };
    }
    case "NO_WORKSPACE_FOR_BRANCH_CREATION": {
      return {
        title: "Unable to create branch without a workspace",
        description:
          "You do not have access to create a branch for this repository. Please ensure you are authenticated and have access to this repository.",
        recoverButton: <DefaultButton />,
      };
    }
    case "LIVE_SESSION_NOT_FOUND": {
      if (isAuthenticated) {
        // Same PAGE_NOT_FOUND
        return {
          title: "Page not found",
          description: (
            <>
              It's likely that this page you're trying to access doesn't exist
              or you don't have the required permissions to access it. Please
              try the following steps:
              <Box as="ul" css={{ paddingInlineStart: "$5" }}>
                <li>
                  Double-check the URL and make sure you're entering it
                  correctly.
                </li>
                <li>
                  Check if you have the necessary permissions to access the
                  page.
                </li>
              </Box>
              If these steps fail, it might mean that there's a technical issue
              on our end.
            </>
          ),
          recoverButton: <DefaultButton />,
        };
      }

      return {
        title: "Login required for page access",
        description: (
          <>
            It's likely that this page you're trying to access doesn't exist or
            you don't have the required permissions to access it. Please try the
            following steps:
            <Box as="ul" css={{ paddingInlineStart: "$5" }}>
              <li>
                Double-check the URL and make sure you're entering it correctly.
              </li>
              <li>
                Check if you have the necessary permissions to access the page.
              </li>
            </Box>
            If these steps fail, it might mean that there's a technical issue on
            our end.
          </>
        ),
        recoverButton: <DefaultButton />,
      };
    }
    case "NO_WORKSPACE_ACCESS": {
      return {
        title: "Unable to access this workspace",
        description:
          "It is likely that you are not a member of the team that this project belongs to. To be able to access the web editor of this repository and edit the code, you need to be invited to the team.",
        recoverButton: <DefaultButton />,
      };
    }

    case "REQUEST_WORKSPACE_ACCESS": {
      const singleWorkspace =
        possibleWorkspacesToRequestAccess &&
        possibleWorkspacesToRequestAccess.length === 1
          ? possibleWorkspacesToRequestAccess[0]
          : null;

      return {
        title: "Request workspace access",
        description: singleWorkspace ? (
          <>
            It looks like you are not a member of the{" "}
            <Text color="neutral-fg-medium">{singleWorkspace.name}</Text>{" "}
            workspace. Request access to join{" "}
            <Text color="neutral-fg-medium">{singleWorkspace.name}</Text>.
          </>
        ) : (
          "It looks like you are not a member of any of the teams that imported this repository. Select your team’s workspace and request access to join."
        ),

        recoverButton: (
          <RequestAccessOptions
            workspaces={possibleWorkspacesToRequestAccess}
          />
        ),
      };
    }

    case "SANDBOX_NOT_FOUND": {
      return {
        title: "Sandbox not found",
        description: (
          <>
            It's likely that the Sandbox you're trying to access doesn't exist
            or you don't have the required permissions to access it.
          </>
        ),
        recoverButton: <DefaultButton />,
      };
    }

    case "DEVBOX_NOT_FOUND": {
      return {
        title: "Devbox not found",
        description: (
          <>
            It's likely that the Devbox you're trying to access doesn't exist or
            you don't have the required permissions to access it.
          </>
        ),
        recoverButton: <DefaultButton />,
      };
    }

    case "REPOSITORY_NOT_FOUND": {
      return {
        title: "Repository or branch not found",
        description: (
          <>
            It's likely that the branch you're trying to access doesn't exist or
            you don't have the required permissions to access it. This may
            happen because:
            <Box as="ul" css={{ paddingInlineStart: "$5" }}>
              <li>
                The branch or repository does not exist on CodeSandbox or
                GitHub.
              </li>
              <li>
                The repository is private and has not been imported to
                CodeSandbox
              </li>
            </Box>
          </>
        ),
        recoverButton: <DefaultButton />,
      };
    }

    case "BRANCH_NOT_FOUND": {
      return {
        title: "Branch not found",
        description: (
          <>
            It's likely that the branch you're trying to access doesn't exist or
            you don't have the required permissions to access it. This may
            happen because:
            <Box as="ul" css={{ paddingInlineStart: "$5" }}>
              <li>
                The branch or repository does not exist on CodeSandbox or
                GitHub.
              </li>
              <li>
                The repository is private and has not been imported to
                CodeSandbox
              </li>
            </Box>
          </>
        ),
        recoverButton: <DefaultButton />,
      };
    }

    case "RESTRICTED_REPOSITORY": {
      return {
        title: "Unable to access repository",
        description: (
          <>
            It's likely that the branch you're trying to access doesn't exist or
            you don't have the required permissions to access it. This may
            happen because:
            <Box as="ul" css={{ paddingInlineStart: "$5" }}>
              <li>You did not sign-in on CodeSandbox using GitHub.</li>
              <li>
                The branch or repository does not exist on CodeSandbox or
                GitHub.
              </li>
              <li>
                The repository is private and has not been imported to
                CodeSandbox
              </li>
            </Box>
          </>
        ),
        recoverButton: <DefaultButton />,
      };
    }

    case "MICROVM_NOT_STARTED":
      return {
        title: "Unable to start the microVM",
        description: `Your changes were saved, but we could not start the microVM running your project. Please try restarting the microVM by clicking the "Restart microVM" button."`,
        recoverButton: <RestartVM error={originalError} />,
      };

    case "SANDBOX_NOT_STARTED":
      return {
        title: "Unable to start the Sandbox",
        description: `Your changes were saved, but we could not start the Sandbox running your project. Don't worry, reloading the page can often fix this issue. You can do this by clicking the "Reload page" button.`,
        recoverButton: <ReloadButton />,
      };

    case "PAGE_NOT_FOUND": {
      return {
        title: "Page not found",
        description: (
          <>
            It's likely that this page you're trying to access doesn't exist or
            you don't have the required permissions to access it. Please try the
            following steps:
            <Box as="ul" css={{ paddingInlineStart: "$5" }}>
              <li>
                Double-check the URL and make sure you're entering it correctly.
              </li>
              <li>
                Check if you have the necessary permissions to access the page.
              </li>
            </Box>
            If these steps fail, it might mean that there's a technical issue on
            our end.
          </>
        ),
        recoverButton: <DefaultButton />,
      };
    }

    case "RUNTIME_ERROR": {
      return {
        title: "Exception occurred",
        description: `Don't worry, reloading the page can often fix this issue. You can do this by clicking the "Reload page" button.`,
        recoverButton: <ReloadButton />,
      };
    }

    case "UNKNOWN_ERROR":
    default:
      return {
        title: "Something went wrong",
        description: isAuthenticated
          ? `Don't worry, reloading the page can often fix this issue. You can do this by clicking the "Reload page" button.`
          : `The content you're trying to access might not be visible because you're not signed in. You can sign in using the button below.`,
        recoverButton: isAuthenticated ? <ReloadButton /> : <DefaultButton />,
      };
  }
};

const RestartVM = ({ error }: { error: string }) => {
  const featureContainer = useContainer();
  const { pitcherInstanceManager } = useEnvironmentInterface();
  const project = useEditor();
  const [session, sessionApi] = useSession();
  const canRestartContainer = project.allowedCapabilities.restartContainer;
  const [loading, setLoading] = React.useState(false);
  const timeout = React.useRef<NodeJS.Timeout>();

  // Sometimes `useContainer` is not available yet here, so we have a fallback
  let restart: () => void;
  if (featureContainer) {
    const [_, dispatch] = featureContainer;
    restart = () => dispatch({ type: "RESTART_CONTAINER" });
  } else {
    restart = () => {
      pitcherInstanceManager.stopPitcherEnvironment(project.id);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    };
  }

  React.useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const isAuthenticated = session.state === "AUTHENTICATED";
  if (!isAuthenticated) {
    return <DefaultButton />;
  }

  return (
    <Tooltip
      content={
        canRestartContainer
          ? "Restarting this microVM may help fix this problem"
          : "Restart is not available when you have read-only access. Please ask the project admin to restart it."
      }
    >
      <Box>
        {
          // During development the manager can give a 401 or 404, where we need to authorize first
          (error.includes("401") || error.includes("404")) &&
          process.env.NODE_ENV !== "production" ? (
            <Button
              css={{ padding: "$1" }}
              onClick={() => sessionApi.signIn()}
              variant="primary"
            >
              Authorize application
            </Button>
          ) : (
            <Button
              disabled={!canRestartContainer}
              loading={loading}
              onClick={() => {
                if (canRestartContainer) {
                  restart();
                  setLoading(true);

                  timeout.current = setTimeout(() => {
                    setLoading(false);
                  }, 5_000);
                }
              }}
              variant="primary"
            >
              Restart microVM
            </Button>
          )
        }
      </Box>
    </Tooltip>
  );
};

const DefaultButton: React.FC = () => {
  const [session, sessionApi] = useSession();
  const [loading, setLoading] = React.useState(false);

  const gitHubScopes = useGitHubScopes();
  const isAuthenticated = session.state === "AUTHENTICATED";

  if (!isAuthenticated) {
    return (
      <Button
        loading={loading}
        onClick={() => {
          setLoading(true);

          // Don't use github provider, because user might user another provider
          // and then the next conditional will take care of github permissions
          sessionApi.signIn().then(() => {
            window.location.reload();
          });
        }}
        variant="primary"
      >
        Sign in
      </Button>
    );
  }

  if (isAuthenticated && !gitHubScopes?.privateRepos) {
    /**
     * restrictsPrivateRepos is true if the user is unauthenticated
     * or if the private repos scope has not been granted.
     */
    return (
      <Button
        loading={loading}
        onClick={() => {
          setLoading(true);
          sessionApi.signIn({
            type: "github",
            includedScopes: "private_repos",
          });
        }}
        variant="primary"
      >
        Review GitHub permissions
      </Button>
    );
  }

  return <ReloadButton />;
};

const ReloadButton: React.FC = () => {
  return (
    <Button onClick={() => window.location.reload()} variant="primary">
      Reload page
    </Button>
  );
};

export const RequestAccessOptions: React.FC<{
  workspaces?: RepositoryWorkspace[];
}> = ({ workspaces }) => {
  const [requestState, setRequestState] = React.useState<
    "IDLE" | "SUCCESS" | "LOADING" | "ERROR"
  >("IDLE");
  const [selectedWorkspaceId, setSelectedWorkspaceId] = React.useState(
    workspaces?.[0]?.id,
  );
  const track = useAnalytics();

  React.useEffect(() => {
    track("error_request_access");
  }, []);

  if (!workspaces || workspaces.length === 0) {
    // Should not happen as the REQUEST_ACCESS error code is only set when there is at least one workspace
    return null;
  }

  const handleRequestAccess = (workspaceId: string) => {
    setRequestState("LOADING");

    csbApi.gql
      .query(requestAccessMutation, { teamId: workspaceId })
      .then(() => {
        setRequestState("SUCCESS");
        track("error_workspace_access_requested");
      })
      .catch(() => {
        setRequestState("ERROR");
      });
  };

  if (requestState === "SUCCESS") {
    return (
      <Text>Your request has been succesfully sent to the workspace admin</Text>
    );
  }

  const publicNoRedirectURL = new URL(window.location.href);
  publicNoRedirectURL.searchParams.append("preventWorkspaceRedirect", "true");

  const accessPublicRepoButton = (
    <Button as="a" href={publicNoRedirectURL.toString()} variant="secondary">
      Open repository (read-only)
    </Button>
  );

  if (workspaces.length === 1) {
    return (
      <Stack horizontal>
        <Button
          onClick={() => {
            handleRequestAccess(workspaces[0].id);
          }}
          variant="primary"
        >
          Send request
        </Button>
        {accessPublicRepoButton}
      </Stack>
    );
  }

  return (
    <Stack gap={4}>
      <Select
        defaultValue={selectedWorkspaceId}
        onValueChange={(wid) => setSelectedWorkspaceId(wid)}
      >
        {workspaces.map((w) => (
          <Select.Item value={w.id}>
            <Stack horizontal>
              <Avatar name={w.name} size="s" src={w.avatarUrl} />
              <Text>{w.name}</Text>
            </Stack>
          </Select.Item>
        ))}
      </Select>
      <Stack horizontal>
        <Button
          loading={requestState === "LOADING"}
          onClick={() => {
            if (selectedWorkspaceId) {
              handleRequestAccess(selectedWorkspaceId);
            }
          }}
          variant="primary"
        >
          Send request
        </Button>
        {accessPublicRepoButton}
      </Stack>
    </Stack>
  );
};

const requestAccessMutation = gql.createMutation(
  "RequestAccessToWorkspace",
  (args: { teamId: string }) => ({
    requestTeamInvitation: [args],
  }),
);

const JoinWorkspaceButton = ({
  eligibleWorkspaceId,
}: {
  eligibleWorkspaceId?: string;
}) => {
  const track = useAnalytics();

  const onClick = () => {
    if (!eligibleWorkspaceId) {
      alert("Something went wrong. Please try again later.");

      return;
    }

    csbApi.gql
      .query(joinWorkspaceMutation, { workspaceId: eligibleWorkspaceId })
      .then(() => {
        track("join_eligible_workspace");

        // Reauthenticate after joining workspace
        window.location.reload();
      })
      .catch(() => {
        alert("Something went wrong. Please try again later.");
      });
  };

  return (
    <Button onClick={onClick} variant="primary">
      Join workspace
    </Button>
  );
};

const joinWorkspaceMutation = gql.createMutation(
  "JoinEligibleWorkspace",
  ({ workspaceId }: { workspaceId: string }) => ({
    joinEligibleWorkspace: [{ workspaceId }, { id: true }],
  }),
);
