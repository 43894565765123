import { useStateTransition } from "@codesandbox/states";
import { useEnvironmentInterface } from "environment-interface";
import type { Editor } from "features/types/editor";
import { useSession } from "hooks/api/useSession";
import { inferProjectForBranch } from "isomorphic/queries";
import { pullRequestSubscription } from "queries/project";
import { useEffect, useReducer } from "react";

import { createHookContext } from "utils/createHookContext";
import { csbApi } from "utils/csbApi";
import { getExperiments } from "utils/experiments";
import { getSandboxGitIdentifier } from "utils/sandbox";

import { router as routerInstance } from "../../router";
import { useAddStatusNotification } from "../notifications/hooks";
import type { EditorRouter } from "../types/router";
import { useReducerDevtools } from "../utils/useReducerDevtools";

import { reducer } from "./reducer";
import type { State } from "./types";

export interface ProviderProps {
  initialState?: State;
  router: EditorRouter;
  type: "sandbox" | "branch";
}

export const useEditorFeature = createHookContext(
  ({ initialState, router, type }: ProviderProps) => {
    const [sessionState, sessionApi] = useSession();
    const { api } = useEnvironmentInterface();

    const dispatchNotification = useAddStatusNotification();

    const getSandboxOrDevboxUrlParams = () => {
      return router.type === "devbox"
        ? router.devbox.urlParams
        : router.sandbox.urlParams;
    };

    const featureReducer = useReducer(
      reducer,
      initialState ||
        (type === "branch"
          ? {
              state: "FETCHING",
              type,
              ...router.branch.urlParams,
              workspaceId: router.queryParams.workspaceId.get() ?? null,
              forcePublicWorkspace: false,
            }
          : {
              state: "FETCHING",
              type,
              ...getSandboxOrDevboxUrlParams(),
              workspaceId: router.queryParams.workspaceId.get() ?? null,
            }),
    );

    useReducerDevtools("editor", featureReducer);

    const [feature, dispatch] = featureReducer;

    useEffect(() => api.events.subscribe(dispatch), []);

    useEffect(() => {
      if (feature.state !== "READY") {
        return;
      }

      if (
        router.type === "devbox" &&
        feature.editor.type === "sandbox" &&
        !feature.editor.isCloud
      ) {
        router.sandbox.updateCurrentSandbox(feature.editor.alias);
      } else if (
        router.type === "sandbox" &&
        feature.editor.type === "sandbox" &&
        feature.editor.isCloud
      ) {
        router.devbox.updateCurrentDevbox(feature.editor.alias);
      }
    }, [feature]);

    function refetchEditorData() {
      dispatch(
        type === "branch"
          ? {
              type: "FETCH_BRANCH",
              ...router.branch.urlParams,
              workspaceId: router.queryParams.workspaceId.get() ?? null,
              forcePublicWorkspace: false,
            }
          : {
              type: "FETCH_SANDBOX",
              ...getSandboxOrDevboxUrlParams(),
              workspaceId: router.queryParams.workspaceId.get() ?? null,
            },
      );
    }

    /**
     * This useEffect handles the browser back navigation
     * triggering a new fetch when the url changes
     */
    useEffect(() => {
      if (feature.state === "ERROR" && !feature.couldNotAccessWorkspace) {
        return;
      }

      // Url does not really change in any other states
      if (feature.state !== "READY") {
        return;
      }

      const hasChangedBranch =
        feature.editor.type === "branch" &&
        (feature.editor.owner !== router.branch.urlParams.owner ||
          feature.editor.repo !== router.branch.urlParams.repo ||
          feature.editor.branch !== router.branch.urlParams.branch ||
          feature.editor.workspace?.id !==
            router.queryParams.workspaceId.get());

      const hasChangedSandbox =
        feature.editor.type === "sandbox" &&
        getSandboxGitIdentifier(feature.editor) !==
          getSandboxOrDevboxUrlParams().id &&
        feature.editor.alias !== getSandboxOrDevboxUrlParams().id &&
        feature.editor.id !== getSandboxOrDevboxUrlParams().id;

      if (hasChangedBranch || hasChangedSandbox) {
        refetchEditorData();
      }
      // We only want to run this when the router actually changes path, which is reflected
      // by a change to its reference
    }, [router]);

    // When changing auth state this component is remounted with a "FETCH_CLIENT_SIDE" rehydration state. In this scenario
    // we want to make sure to infer which version of the project to show. Typically signing in/out flips between a public and workspace
    // version and the url needs to reflect that, so when refreshing we have the same behaviour
    useEffect(
      () =>
        sessionApi.onSessionChange(({ session, prevSession }) => {
          const fetchSandbox = () => refetchEditorData();

          // When we authenticate related to having a create query we just reload the page cause the server
          // is responsible for creating this branch
          if (
            session.state === "AUTHENTICATED" &&
            router.branch.queryParams.create.get()
          ) {
            return window.location.reload();
          }

          const fetchBranch = () => {
            // When AUTHENTICATED we infer which version of the project to use

            if (session.state === "AUTHENTICATED") {
              inferProjectForBranch(csbApi, {
                owner: router.branch.urlParams.owner,
                repo: router.branch.urlParams.repo,
                branch: router.branch.urlParams.branch,
                workspaceId: null,
              }).then((inferredProject) => {
                // If we infer to the same workspaceId as in the url, we need to manually move things ahead
                if (
                  inferredProject &&
                  inferredProject.team &&
                  inferredProject.team.id ===
                    router.queryParams.workspaceId.get()
                ) {
                  // We already have the data, but for simplicities sake and edge case scenario we just use existing refetch logic
                  refetchEditorData();

                  // If we infer to a project with a different team, we fetch that and the URL will get updated when the project data is available
                } else if (inferredProject && inferredProject.team) {
                  router.queryParams.workspaceId.set(inferredProject.team.id);

                  refetchEditorData();

                  // If we are still on the public version we still need to refetch to get the proper state
                } else if (inferredProject) {
                  refetchEditorData();
                }
                // We would never end up in a state when authenticated on a project and you do not have access
              });
              return;
            }

            // When UNAUTHENTICATED we just remove the workspaceId, if it exists, as it will try to fetch the public version
            if (router.queryParams.workspaceId.get()) {
              router.queryParams.workspaceId.delete();
              refetchEditorData();
              return;
              // Or we just regrab the public version to get proper authentication state
            }

            refetchEditorData();
          };

          // You might change your authentication as we are showing an error page, in that case we
          // want to refetch
          if (feature.state === "ERROR" && type === "sandbox") {
            return fetchSandbox();
          }

          if (feature.state === "ERROR" && type === "branch") {
            return fetchBranch();
          }

          // You can not really change your auth state in any other state than "READY" from here
          if (feature.state !== "READY") {
            return;
          }

          if (feature.preventAuthenticationRefetch) {
            return;
          }

          if (
            prevSession.state === "AUTHENTICATED" &&
            session.state === "AUTHENTICATED"
          ) {
            // Don't fetch if we're authenticated and we're again authenticated
            return;
          }

          if (type === "branch") {
            return fetchBranch();
          }

          fetchSandbox();
        }),
      [feature, sessionApi],
    );

    useStateTransition(
      feature,
      {
        READY: {
          UPDATE_SANDBOX: "READY",
        },
      },
      ({ editor }, _, { editor: oldEditor }) => {
        if (editor.type === "branch" || oldEditor.type === "branch") {
          return;
        }

        if (editor.alias === oldEditor.alias) {
          return;
        }

        if (editor.isCloud) {
          router.devbox.updateCurrentDevbox(editor.alias);
        } else {
          router.sandbox.updateCurrentSandbox(editor.alias);
        }
      },
    );

    useStateTransition(
      feature,
      {
        READY: {
          SWITCH_BRANCH: "FETCHING",
          SWITCH_SANDBOX: "FETCHING",
          SWITCH_DEVBOX: "FETCHING",
        },
      },
      (state, action) => {
        if (getExperiments().webVSCode) {
          const baseUrl = new URL(
            state.type === "branch"
              ? routerInstance.url("github", {
                  owner: state.owner,
                  repo: state.repo,
                  branch: state.branch,
                })
              : routerInstance.url("devbox", {
                  id: state.id,
                }),
            window.location.origin,
          );

          // Move over the current url params as well
          const currentUrl = new URL(window.location.href);
          for (const [key, value] of currentUrl.searchParams.entries()) {
            baseUrl.searchParams.set(key, value);
          }

          if (state.type === "branch" && state.create) {
            baseUrl.searchParams.set("create", "true");
          }
          if (state.workspaceId) {
            baseUrl.searchParams.set("workspaceId", state.workspaceId);
          }

          window.location.href = baseUrl.toString();

          return;
        }

        if (state.type === "branch") {
          router.branch.goToBranch({
            owner: state.owner,
            repo: state.repo,
            branch: state.branch,
            workspaceId: state.workspaceId,
            create: state.create ? "true" : undefined,
          });
        } else if (action.type === "SWITCH_DEVBOX") {
          router.devbox.goToDevbox(state.id);
        } else {
          router.sandbox.goToSandbox(state.id);
        }
      },
    );

    useStateTransition(feature, "FETCHING", (state) => {
      if (state.type === "branch") {
        const create = Boolean(router.branch.queryParams.create.get());

        api.fetchBranch({
          owner: state.owner,
          repo: state.repo,
          branch: state.branch,
          workspaceId: state.workspaceId,
          create,
          forcePublic: state.forcePublicWorkspace,
        });

        if (create) {
          // If the fetch tries to create a branch we'll remove the query
          router.branch.queryParams.create.delete();
        }
      } else {
        api.fetchSandbox(state.id);
      }
    });

    /**
     * Update the URL in the situation in which the branch name is not originally present
     * or when the workspaceId returned by backend differs then the one in the URL
     **/
    useStateTransition(
      feature,
      { FETCHING: "API:FETCH_EDITOR_DATA_SUCCESS" },
      ({ editorData }) => {
        if (
          editorData.type === "branch" &&
          (editorData.owner !== router.branch.urlParams.owner ||
            editorData.repo !== router.branch.urlParams.repo ||
            editorData.branch !== router.branch.urlParams.branch ||
            editorData.workspace?.id !== router.queryParams.workspaceId.get())
        ) {
          router.branch.goToBranch({
            owner: editorData.owner,
            repo: editorData.repo,
            branch: editorData.branch,
            workspaceId: editorData.workspace?.id ?? null,
          });
        }
      },
    );

    useStateTransition(
      feature,
      {
        RENAMING_BRANCH: {
          BRANCH_RENAME_ERROR: "READY",
        },
      },
      ({ editor }, { error }) => {
        if (editor.type !== "branch") {
          return;
        }

        router.branch.updateCurrentBranch({
          owner: editor.owner,
          repo: editor.repo,
          branch: editor.branch,
        });

        dispatchNotification({
          message: error,
          status: "error",
        });
      },
    );

    useStateTransition(
      feature,
      {
        RENAMING_BRANCH: {
          BRANCH_RENAME_TAKEN_ERROR: "READY",
        },
      },
      ({ editor }, { nameTaken }) => {
        if (editor.type !== "branch") {
          return;
        }

        router.branch.updateCurrentBranch({
          owner: editor.owner,
          repo: editor.repo,
          branch: editor.branch,
        });

        dispatchNotification({
          message: `Branch ${nameTaken} already exists`,
          status: "error",
          actions: [
            {
              text: "Open",
              onAction: () =>
                dispatch({
                  type: "SWITCH_BRANCH",
                  name: nameTaken,
                }),
            },
          ],
        });
      },
    );

    // Handles the scenario when the current client renames the branch
    useStateTransition(feature, "RENAMING_BRANCH", ({ editor, newName }) => {
      if (editor.type !== "branch") {
        return;
      }

      router.branch.updateCurrentBranch({
        owner: editor.owner,
        repo: editor.repo,
        branch: newName,
      });
    });

    useStateTransition(feature, "REMOVING_BRANCH", ({ branch, editor }) => {
      if (editor.type !== "branch") {
        return;
      }

      api.removeBranchFromCodesandbox({
        owner: editor.owner,
        repo: editor.repo,
        workspaceId: editor.workspace?.id ?? null,
        branch,
      });
    });

    useStateTransition(
      feature,
      { REMOVING_BRANCH: "API:REMOVE_REMOTE_BRANCH_SUCCESS" },
      (_, { editor }) => {
        if (editor.type !== "branch") {
          return;
        }

        router.branch.goToBranch({
          owner: editor.owner,
          repo: editor.repo,
          branch: editor.defaultBranchName,
          workspaceId: editor.workspace?.id ?? null,
        });
      },
    );

    useStateTransition(
      feature,
      { REMOVING_BRANCH: "API:REMOVE_REMOTE_BRANCH_ERROR" },
      () => {
        dispatchNotification({
          message: "Branch deletion failed",
          status: "error",
        });
      },
    );

    // Handles the scenario when someone else renames the branch
    useStateTransition(
      feature,
      {
        READY: {
          SET_BRANCH_NAME: "READY",
        },
      },
      ({ editor }, { name }) => {
        if (editor.type !== "branch") {
          return;
        }

        router.branch.updateCurrentBranch({
          owner: editor.owner,
          repo: editor.repo,
          branch: name,
        });
        dispatchNotification({
          message: `Branch was renamed as "${name}"`,
          status: "info",
        });
      },
    );

    useStateTransition(
      feature,
      {
        READY: {
          SWITCH_SEAMLESS_INSTANCE: "READY",
        },
      },
      ({ editor }) => {
        if (editor.type === "branch") {
          router.branch.goToBranch({
            owner: editor.owner,
            repo: editor.repo,
            branch: editor.branch,
            workspaceId: editor.workspace?.id ?? null,
          });
        } else if (editor.isCloud) {
          router.devbox.goToDevbox(editor.alias);
        } else {
          router.sandbox.goToSandbox(editor.alias);
        }
      },
    );

    useStateTransition(
      feature,
      {
        READY: {
          IMPORT_PROJECT: "FETCHING",
        },
      },
      (_, { workspace, newProject: project }) => {
        dispatchNotification({
          message: `Imported repository ${project.owner}/${project.repo} to ${workspace.name}`,
          status: "info",
        });
      },
    );

    const shouldSubscribe =
      sessionState.state === "AUTHENTICATED" &&
      (feature.state === "READY" ||
        feature.state === "REMOVING_BRANCH" ||
        feature.state === "RENAMING_BRANCH") &&
      feature.editor.type === "branch" &&
      feature.editor.githubAppInstalled;

    useEffect(() => {
      if (shouldSubscribe && feature.editor.type === "branch") {
        return csbApi.gql.subscribe(
          pullRequestSubscription,
          (data) => {
            if (data.branchEvents.pullRequest) {
              dispatch({
                type: "PULL_REQUEST_UPDATED",
                data: data.branchEvents.pullRequest,
              });
            }
          },
          {
            owner: feature.editor.owner,
            repo: feature.editor.repo,
            branch: feature.editor.branch,
          },
        );
      }
      // We only want to trigger this effect once we have gotten the initial data, as the state
      // will continue to update after that
    }, [shouldSubscribe]);

    return featureReducer;
  },
);

export const useEditor = () => {
  const [editorFeature] = useEditorFeature();

  if (
    editorFeature.state === "READY" ||
    editorFeature.state === "RENAMING_BRANCH" ||
    editorFeature.state === "REMOVING_BRANCH"
  ) {
    return editorFeature.editor as Editor;
  }

  throw new Error("Invalid use of useEditor hook");
};

export const useBranchEditor = () => {
  const editor = useEditor();

  if (editor.type === "branch") {
    return editor;
  }

  throw new Error("The editor is a SANDBOX, but you want to consume a BRANCH");
};

export const useSandboxEditor = () => {
  const editor = useEditor();

  if (editor.type === "sandbox") {
    return editor;
  }

  throw new Error("The editor is a BRANCH, but you want to consume a SANDBOX");
};

export const EditorProvider = useEditorFeature.Provider;

export const EditorContext = useEditorFeature.context;
