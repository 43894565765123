import type { ActionImpl, KBarResultsProps } from "@codesandbox/kbar";
import { useMatches, KBarResults } from "@codesandbox/kbar";
import React, { memo } from "react";

import { styled } from "../../theme";
import { Box } from "../Box";
import { Divider } from "../Divider";
import { Icon } from "../Icon";
import { Stack } from "../Stack";

const Wrapper = styled("div", {
  fontFamily: "$base",
});

const Section = styled("div", {
  paddingLeft: "$4",
  fontSize: "$caption1",
  py: "$2",
});

const Item = styled("div", {
  paddingRight: "$6",
  py: "$2",
  paddingLeft: "$6",
  cursor: "var(--csb-cursor)",
  fontSize: "$base",

  variants: {
    active: {
      true: {
        background: "$neutral-bg-focus",

        svg: {
          color: "$neutral-fg-subtle",
        },
      },
      false: {},
    },
  },
});

const Shortcut = styled("div", {
  borderRadius: "$2",
  padding: "$1",
  lineHeight: 1,
  margin: 0,
  height: "$5",
  minWidth: "$5",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textTransform: "uppercase",
  backgroundColor: "$neutral-bg-focus",
  fontFamily: "$base",
  fontSize: "12px",
  color: "$neutral-fg-high",
});

export type ResultItem = string | ActionImpl;

export const Results: React.FC = memo(() => {
  const { results } = useMatches();

  const hasResults = results.length > 0;

  const onRender: KBarResultsProps["onRender"] = ({ item, active }) => {
    // In this case, the item is a section, which cannot be selected by users
    if (typeof item === "string") {
      return <Section>{item}</Section>;
    }

    /**
     * If the item has children, we refer to it as a group
     * in practice this means a user who selects this item will
     * see nested commands.
     * */
    const isGroup = item.children.length !== 0;

    if (item.name === "LOADING") {
      return (
        <Item css={{ paddingTop: "$1" }} onClick={(e) => e.stopPropagation()}>
          <Stack
            css={{ alignItem: "center", justifyContent: "flex-start" }}
            gap={3}
            horizontal
          >
            <Icon size={4} type="progress" />
            <Box css={{ truncateText: true }}>{item.subtitle}</Box>
          </Stack>
        </Item>
      );
    }

    if (active && item.onSelect) {
      item.onSelect();
    }

    return (
      <Item active={active}>
        <Stack
          css={{ alignItem: "center", justifyContent: "flex-start" }}
          gap={3}
          horizontal
        >
          {item.icon && item.icon}

          <Box
            css={{
              color: "$neutral-fg-high",
              whiteSpace: "nowrap",
            }}
          >
            {item.name}
            {/** For groups, always show ellipses to indicate that another step will be shown on selection */}
            {isGroup && <>&hellip;</>}
          </Box>

          {/**
              Hack: We don't show subtitles here because we also use them as placeholders
              This is flaky because a group might have no children (for example if the commands are async)
            */}
          {!isGroup && <Box css={{ truncateText: true }}>{item.subtitle}</Box>}
          {item.shortcut && item.shortcut.length > 0 && (
            <Stack
              css={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
              gap={1}
              horizontal
            >
              {item.shortcut.map((shortcut, index) => (
                <Shortcut key={`${item.name}-${shortcut}-${index}`}>
                  {shortcut}
                </Shortcut>
              ))}
            </Stack>
          )}
        </Stack>
      </Item>
    );
  };

  return (
    <Wrapper className="command-palette-search-results">
      {hasResults && (
        <Box css={{ px: "$4", paddingBottom: "$2" }}>
          <Divider />
        </Box>
      )}
      <KBarResults items={results} onRender={onRender} />
    </Wrapper>
  );
});
