import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
} from "@codesandbox/kbar";
import type { KBarProviderProps } from "@codesandbox/kbar";
import React from "react";

import { styled } from "../../theme";

import { Palette } from "./Palette";

const Positioner = styled(KBarPositioner, {
  zIndex: "$commandPalette",
});

export const CommandPalette: React.FC<KBarProviderProps> = ({
  children,
  ...providerProps
}) => {
  return (
    <KBarProvider {...providerProps}>
      <KBarPortal>
        <Positioner>
          <KBarAnimator>
            <Palette />
          </KBarAnimator>
        </Positioner>
      </KBarPortal>
      {children}
    </KBarProvider>
  );
};
