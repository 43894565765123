import React from "react";

import { styled } from "../../theme";

import { Results } from "./Results";
import { Search } from "./Search";
import { useCommandPalette } from "./hooks";
import { useGroup } from "./useGroup";

import type { PaletteCommand } from ".";

const Base = styled("div", {
  maxWidth: "90vw",
  width: "70ch",
  position: "relative",
});

const Body = styled("div", {
  backgroundColor: "$neutral-bg-medium",
  borderRadius: "$2",
  boxShadow: "$popup",
});

const Breadcrumbs = styled("div", {
  top: "-$7",
  zIndex: "$commandPalette",
  position: "absolute",
  display: "flex",
  maxWidth: "100%",
  gap: "$2",
});

const Scope = styled("div", {
  fontSize: "$caption2",
  flexGrow: 0,
  letterSpacing: "-0.01em",
  whiteSpace: "nowrap",
  padding: "$0 $2",
  background: "$neutral-bg-high",
  borderRadius: "9999px",
  marginBottom: "$2",
  display: "inline-flex",
  alignItems: "center",
  color: "$neutral-fg-subtle",
  height: "$5",
  zIndex: "$commandPalette",

  svg: {
    marginRight: "$1",
    width: "$3",
    height: "$3",
  },
});

export const Palette: React.FC = () => {
  const group = useGroup();
  const { query } = useCommandPalette();

  return (
    <Base className="command-palette">
      {group.state === "GROUP" && (
        /**
         * TODO: with very deep Breadcrumbs the UI starts to degrade.
         * To improve this, adjust the UI to show only the most recent breadcrumbs
         * and the first one.
         *
         * For example, changing the UI from
         *
         * One > Two > Three > Four > Five > Six > Etc
         *
         * To:
         *
         * One ... Six > Etc
         *
         * Or something similar
         */
        <Breadcrumbs>
          {group.ancestors.length > 0 &&
            group.ancestors.map((ancestor: PaletteCommand) => (
              <Scope
                key={ancestor.id}
                onClick={() => query.setCurrentRootAction(ancestor.id)}
              >
                {ancestor.icon && ancestor.icon}
                {ancestor.name}
              </Scope>
            ))}
          <Scope>
            {group.icon && group.icon}
            {group.subtitle || group.name}
          </Scope>
        </Breadcrumbs>
      )}
      <Body>
        <Search />
        <Results />
      </Body>
    </Base>
  );
};
