import type { gql } from "@codesandbox/api";
import { useEnvironmentInterface } from "environment-interface";
import type { VisibilityNumber } from "features/editor/sandboxUtils";
import { useEffect, useMemo, useRef } from "react";

/**
 * Usage
 *
 * This hook is an abstraction on top of the amplitude api exposed
 * by the environment interface. It can, and should, be abstracted
 * to express common use cases such as tracking something only once
 * or tracking on change.
 *
 * When an action or page is added, if it's relevant for tracking,
 * we should the event key (and other relevant info) to the
 * `EventParameters` definition. Then the actual event name should
 * be added to `mapEventKeyToMetricName`, this way, function calls
 * don't need to receive lengthy strings.
 *
 * const track = useAnalytics();
 * track('my_event_key', { ...eventParams })
 */

export type FromEditor = {
  event_source: "editor";
};

export type FromDashboard = {
  event_source: "dashboard";
};

export type FromCommandPalette = {
  event_source: "command_palette";
};

export type FromContextMenu = {
  event_source: "context_menu";
};

export type FromInterface = {
  event_source: "ui";
};

export type FromMainMenu = {
  event_source: "main_menu";
};

export type FromKeyboardShortcut = {
  event_source: "keyboard";
};

export type FromPanelPlaceholder = {
  event_source: "panel_placeholder";
};

export type FromAlternativeEvent = {
  event_source: "altclick" | "dblclick"; // Expand in the future?
};

export type FromOnboarding = {
  event_source: "onboarding";
};

// A tuple of event key and aditional info, if applicable.
export type AnalyticsEventParameters =
  | ["open_file"]
  | ["unauthorized_preview"]
  | ["click_menu"]
  | ["click_menu_item", { name: string }]
  | ["env_variables"]
  | ["toggle_env_variables", { mode: "form" | "plain" }]
  | ["vs_code", FromEditor | FromDashboard | FromCommandPalette]
  | ["upload_file", { event_source: "drag_drop" | "file_input" }]
  | [
      "new_devtool",
      (
        | FromEditor
        | FromCommandPalette
        | FromKeyboardShortcut
        | FromAlternativeEvent
        | FromContextMenu
        | FromPanelPlaceholder
      ),
    ]
  | ["close_tab"]
  | ["close_others"]
  | ["close_all"]
  | ["remove_panel"]
  | ["split_panel"]
  | [
      "create_terminal",
      (
        | FromEditor
        | FromCommandPalette
        | FromContextMenu
        | FromKeyboardShortcut
        | FromAlternativeEvent
        | FromPanelPlaceholder
      ),
    ]
  | ["open_terminal", FromEditor | FromCommandPalette]
  | ["stop_terminal"]
  | ["open_system_metrics", { event_source: "new_devtool" | "badge" }]
  | ["new_task", FromEditor]
  | [
      "open_docs",
      (
        | FromInterface
        | FromMainMenu
        | FromKeyboardShortcut
        | FromCommandPalette
        | FromPanelPlaceholder
      ),
    ]
  | ["run_task", FromEditor]
  | ["restart_task", FromEditor]
  | ["open_preview", FromEditor]
  | ["stop_task", FromEditor]
  | ["stop_preview", FromEditor]
  | ["restart_preview", FromEditor]
  | ["open_preview_logs", FromEditor]
  | ["open_inspector", FromEditor]
  | ["open_preview_window", FromEditor]
  | ["open_preview_browser_tab", FromEditor]
  | ["open_preview_popup", FromEditor]
  | ["open_docked_preview_navbutton", FromEditor]
  | ["click_git", FromInterface | FromCommandPalette | FromKeyboardShortcut]
  | [
      "click_pull_request",
      FromInterface | FromCommandPalette | FromKeyboardShortcut,
    ]
  | [
      "click_pull_request_no_gh_app",
      FromInterface | FromCommandPalette | FromKeyboardShortcut,
    ]
  | ["click_search", FromInterface | FromCommandPalette | FromKeyboardShortcut]
  | [
      "click_file_explorer",
      FromInterface | FromCommandPalette | FromKeyboardShortcut,
    ]
  | ["open_editor_settings", FromMainMenu | FromInterface]
  | ["open_command_palette", FromMainMenu | FromKeyboardShortcut]
  | [
      "open_command_palette",
      FromMainMenu | FromKeyboardShortcut | FromOnboarding,
    ]
  | ["open_keyboard_shortcuts", FromMainMenu]
  | ["open_theme_picker", FromMainMenu | FromCommandPalette | FromInterface]
  | ["layout_default", FromMainMenu | FromCommandPalette | FromKeyboardShortcut]
  | [
      "layout_alternative",
      FromMainMenu | FromCommandPalette | FromKeyboardShortcut,
    ]
  | ["layout_embed", FromMainMenu | FromCommandPalette]
  | [
      "layout_focus_mode",
      FromMainMenu | FromCommandPalette | FromKeyboardShortcut,
    ]
  | [
      "layout_hide_sidebar",
      FromMainMenu | FromCommandPalette | FromKeyboardShortcut,
    ]
  | [
      "layout_show_sidebar",
      FromMainMenu | FromCommandPalette | FromKeyboardShortcut,
    ]
  | [
      "layout_hide_devtools",
      FromMainMenu | FromInterface | FromCommandPalette | FromKeyboardShortcut,
    ]
  | [
      "layout_show_devtools",
      FromMainMenu | FromInterface | FromCommandPalette | FromKeyboardShortcut,
    ]
  | [
      "layout_hide_shells",
      FromMainMenu | FromInterface | FromCommandPalette | FromKeyboardShortcut,
    ]
  | [
      "layout_show_shells",
      FromMainMenu | FromInterface | FromCommandPalette | FromKeyboardShortcut,
    ]
  | ["git_commit_push", FromEditor]
  | ["git_create_pr"]
  | ["git_open_url_pr"]
  | [
      "git_discard_changes",
      FromEditor & { type: "single_file" | "multiple_files" | "all_files" },
    ]
  | ["git_pr_open", FromEditor]
  | [
      "dashboard_navigate_to_editor",
      { path: string; branch_type?: "contribution" | "regular" },
    ]
  | ["dashboard_import_modal_open"]
  | ["dashboard_create_project", { source: "repository" | "template" }]
  | ["dashboard_import_project", { source: "repository" | "template" }]
  | ["dashboard_authorize_gh"]
  | ["dashboard_list_view", { page: "recent" | "workspace" }]
  | ["dashboard_grid_view", { page: "recent" | "workspace" }]
  | [
      "fork",
      {
        event_source:
          | "topbar"
          | "getting_started_screen"
          | "command_palette"
          | "seamless";
        target: "default" | "current" | "fork" | "contribution_branch";
      },
    ]
  | [
      "fork_mouse_enter",
      {
        event_source: "topbar";
        target: "default" | "contribution_branch";
      },
    ]
  | [
      "fork_mouse_leave",
      {
        event_source: "topbar";
        target: "default" | "contribution_branch";
      },
    ]
  | ["topbar_open_command_palette", { item: "change" | "create" }]
  | ["pageview", { path: string }]
  | ["anon_user"]
  | ["gh_app_install_from_settings", FromEditor]
  | ["gh_app_install_from_pr_panel", FromEditor]
  | ["gh_app_close_install_popup", FromEditor]
  | [
      "reconnecting",
      FromEditor & { attemptCount?: number; isAutomatic: boolean },
    ]
  | ["manual_reconnect", FromEditor]
  | [
      "error_page",
      FromEditor & { description: string; event?: string; context?: string },
    ]
  | ["invite_collaborator_email", { authorization: string }]
  | ["invite_collaborator_username", { authorization: string }]
  | [
      "copy_sandbox_url",
      {
        type: "share" | "embed" | "iframe" | "embed_url" | "markdown" | "html";
      },
    ]
  | [
      "reset_branch_to_remote",
      {
        event_source: "topbar" | "command_palette";
      },
    ]
  | ["update_sandbox_title", { event_source: "header" | "sandbox info pane" }]
  | [
      "update_sandbox_privacy",
      {
        event_source: "share modal" | "sandbox info pane";
        privacy: VisibilityNumber;
      },
    ]
  | [
      "update_sandbox_template_icon",
      { event_source: "sandbox info pane"; iconUrl: string | undefined },
    ]
  | ["install_ios"]
  | ["editor_open_editor_options"]
  | ["click_upgrade_vm_specs"]
  | [
      "apply_new_vm_specs",
      { tier: number; tierShortid: string; tierName: string },
    ]
  | ["show_resource_alert", { type: string }]
  | ["stripe_trial_payment_reminder_seen"]
  | ["stripe_trial_payment_reminder_dismissed"]
  | ["stripe_trial_payment_reminder_add_info"]
  | ["pitcher_late_pong"]
  | ["embed_open_editor"]
  | ["visibility", { isVisible: boolean }]
  | ["download", { tag: string }]
  | [
      "review_submit",
      {
        type: gql.GitHubPullRequestReviewAction;
        withText: boolean;
        numberOfComments: number;
      },
    ]
  | ["review_comment"]
  | ["review_comment_open"]
  | ["review_comment_close"]
  | ["review_comment_github"]
  | ["review_comment_pending"]
  | ["review_comment_reply"]
  | ["change_theme", { name: string }]
  | ["dependency_manager_install"]
  | ["dependency_manager_uninstall"]
  | ["dependency_manager_update"]
  | ["dependency_manager_search"]
  | ["debug_devtools_open"]
  | ["debug_devtools_close"]
  | [
      "env_setup_devtool_open",
      { import: boolean; protectBranch: boolean; type: "branch" | "devbox" },
    ]
  | ["env_setup_devtool_documentation"]
  | ["devcontainer_autodetect", { templateId: string }]
  | ["devcontainer_skip"]
  | ["devcontainer_custom", { templateId: string }]
  | ["devcontainer_select_template", { templateId: string }]
  | ["devcontainer_add_feature"]
  | ["devcontainer_remove_feature"]
  | ["env_setup_devtool_githubapp"]
  | ["env_setup_devtool_set_envvars"]
  | ["env_setup_devtool_remove_envvars"]
  | ["env_setup_devtool_prefilled_envvars"]
  | ["env_setup_summary_navigate", { step: string }]
  | [
      "env_setup_summary_submit",
      {
        devContainerCompleted: boolean;
        tasksCompleted: boolean;
        envVarsCompleted: boolean;
        githubAppCompleted: boolean;
        import: boolean;
        protectBranch: boolean;
        type: "branch" | "devbox";
      },
    ]
  | ["env_setup_summary_skip"]
  | ["env_setup_navigate_next", { current: string; next: string }]
  | ["env_setup_navigate_prev"]
  | ["env_setup_devtool_add_setup_task"]
  | ["env_setup_devtool_remove_setup_task"]
  | ["env_setup_devtool_prefilled_setup_task"]
  | ["env_setup_devtool_reorder_setup_task"]
  | [
      "env_setup_devtool_error",
      {
        errorMessage: string;
        import: boolean;
        protectBranch: boolean;
        type: "branch" | "devbox";
      },
    ]
  | [
      "embed_open_modal",
      { type: "branch" | "cloud_sandbox" | "browser_sandbox" },
    ]
  | ["embed_set_option", { optionId: string }]
  | ["embed_share_options"]
  | ["editor_loaded", { type: "sandbox" | "branch"; isCloud: boolean }]
  | [
      "convert_to_devbox_open",
      {
        location:
          | "primary-button"
          | "terminal"
          | "vscode"
          | "live-session"
          | "sandbox-limit";
      },
    ]
  | ["convert_to_devbox_submit"]
  | ["codeium_opened_modal"]
  | ["codeium_closed_modal"]
  | ["codeium_enable", { location: "settings" | "modal" }]
  | ["codeium_disable", { location: "settings" | "modal" }]
  | ["codeium_insert_suggestion"]
  | ["codeium_reach_free_sandbox_limit"]
  | [
      "setup_tasks_started",
      {
        import: boolean;
        protectBranch: boolean;
        type: "branch" | "devbox";
      },
    ]
  | [
      "setup_tasks_completed",
      {
        runningTasksCount: number;
        import: boolean;
        protectBranch: boolean;
        type: "branch" | "devbox";
      },
    ]
  | [
      "setup_task_failed",
      {
        name: string;
        import: boolean;
        protectBranch: boolean;
        type: "branch" | "devbox";
      },
    ]
  | [
      "pitcher_initialized",
      { vmId: string; duration: number; bootupType: string },
    ]
  | ["live_session_started"]
  | ["live_session_stopped"]
  | ["live_session_guest_joined", { numberOfGuests: number }]
  | ["live_session_guest_permission_changed"]
  | ["live_session_default_permission_changed"]
  | ["frozen_message", { canBuyPro: boolean }]
  | ["share_private_draft_open_drafts"]
  | ["share_private_draft_move_out_of_drafts", { location: string }]
  | ["restricted_sandbox_upgrade_to_pro"]
  | ["restricted_sandbox_contact_us"]
  | ["public_restricted_draft_made_public"]
  | ["public_restricted_draft_display"]
  | ["fork_sandbox_limit"]
  | ["seamless_fork_sandbox_limit"]
  | ["procted_branch_toggle", { value: boolean }]
  | ["codeium_upgrade_to_pro"]
  | ["export_to_github"]
  | ["error_request_access"]
  | ["error_workspace_access_requested"]
  | ["restart_failed"]
  | ["branch_changed_manually", { branch: string; checkedOutBranch: string }]
  | ["breadcrumb_hover_folder", { isDraft: boolean }]
  | ["breadcrumb_open_folder", { isDraft: boolean }]
  | ["breadcrumb_hover_repo"]
  | ["breadcrumb_open_repo"]
  | ["breadcrumb_hover_workspace", { type: "branch" | "devbox" | "sandbox" }]
  | ["breadcrumb_open_workspace", { type: "branch" | "devbox" | "sandbox" }]
  | ["branch_checkout"]
  | ["learn_more_branch_checkout"]
  | [
      "create_repo_from_template",
      { id: string; title: string; location: string },
    ]
  | ["install_missing_dep"]
  | ["join_eligible_workspace"]
  | ["eligible_workspace_found"]
  | ["show_changelog_note"]
  | ["open_changelog"]
  | ["set_vscode_web", { enabled: boolean }]
  | ["set_vscode_server", { enabled: boolean }]
  | ["set_vscode_marketplace", { enabled: boolean }]
  | ["test_open_from_preview"]
  | ["test_open_from_configfile"]
  | ["test_run_test"]
  | ["test_toggle_watch"]
  | ["test_toggle_description"]
  | ["test_run_only_this_file"];

const MAP_EVENT_KEY_TO_METRIC_NAME: Record<
  AnalyticsEventParameters[0],
  string
> = {
  create_repo_from_template: "Create repo from template",
  branch_checkout: "Branch checkout",
  learn_more_branch_checkout: "Learn more - Branch checkout",
  branch_changed_manually: "Branch changed manually",
  restart_failed: "Restart failed",
  setup_tasks_completed: "Setup tasks completed",
  setup_tasks_started: "Setup tasks started",
  unauthorized_preview: "Unauthorized preview",
  pageview: "pageview",
  open_file: "Open File",
  click_menu: "Global - Click Menu",
  click_menu_item: "Global - Click Menu Item",
  env_variables: "Global - Env variables",
  toggle_env_variables: "Env variables - Toggle",
  editor_open_editor_options: "Editor - Open choose editor dropdown",
  vs_code: "VS Code - Open From Editor",
  install_ios: "Editor - Click iOS install link",
  upload_file: "Editor - Upload binary files",
  new_devtool: "Devtool - New devtool",
  create_terminal: "Devtool - Create terminal",
  open_terminal: "Devtools - Open terminal",
  close_tab: "Devtools - Close tab",
  close_others: "Devtools - Close other tabs",
  close_all: "Devtools - Close all tabs",
  remove_panel: "Devtools - Remove panel",
  split_panel: "Devtools - Split panel",
  open_system_metrics: "Devtools - Open system metrics",
  open_docs: "Devtools - Open documentation",
  stop_terminal: "Devtools - Stop terminal",
  new_task: "Devtools - Open command palette tasks",
  run_task: "Devtools - Run task",
  restart_task: "Devtools - Restart task",
  stop_task: "Devtools - Stop task",
  stop_preview: "Devtools - Stop preview",
  open_preview: "Devtools - Open preview",
  open_preview_browser_tab: "Devtools - Open preview browser tab",
  restart_preview: "Devtools - Restart preview",
  open_inspector: "DevTool Preview - toggle inspector",
  open_docked_preview_navbutton: "DevTool Preview - expand navigation button",
  open_preview_window: "DevTool Preview - open window",
  open_preview_popup: "DevTool Preview - open popup",
  open_preview_logs: "Devtools - Open preview logs",
  click_git: "Editor - Click git",
  click_pull_request: "Editor - Click pull request",
  click_pull_request_no_gh_app: "Editor - Click pull request - no github app",
  click_search: "Editor - Click search",
  click_file_explorer: "Editor - Click file explorer",
  git_commit_push: "Editor - Git - Commit and push",
  git_create_pr: "Editor - Git - Create PR",
  git_open_url_pr: "Editor - Git - Open PR URL",
  git_discard_changes: "Editor - Git - Discard changes",
  git_pr_open: "Editor - PR - Open PR",

  gh_app_install_from_settings: "Editor - Settings - Click GH App install",
  gh_app_install_from_pr_panel: "Editor - PR panel - Click GH App install",
  gh_app_close_install_popup: "Editor - Close GitHub app install popup",

  open_editor_settings: "Editor - Settings",
  open_theme_picker: "Editor - Theme picker",
  open_command_palette: "Editor - Command palette",
  open_keyboard_shortcuts: "Editor - Keyboard shorcuts",
  layout_default: "Editor - Set default layout",
  layout_alternative: "Editor - Set alternative layout",
  layout_embed: "Editor - Set embed preview",
  layout_focus_mode: "Editor - Set focus mode",
  layout_hide_devtools: "Editor - Hide devtools",
  layout_hide_shells: "Editor - Hide shells",
  layout_hide_sidebar: "Editor - Hide sidebar",
  layout_show_devtools: "Editor - Show devtools",
  layout_show_shells: "Editor - Show shells",
  layout_show_sidebar: "Editor - Show sidebar",

  dashboard_import_modal_open: "Dashboard - Import project opened",
  dashboard_create_project: "Dashboard - Click create project",
  dashboard_import_project: "Dashboard - Click import project",
  dashboard_authorize_gh: "Dashboard - Manage GitHub permissions",
  dashboard_list_view: "Dashboard - Toggle list view",
  dashboard_grid_view: "Dashboard - Toggle grid view",
  dashboard_navigate_to_editor: "Dashboard - Open Branch",
  fork: "Editor - Fork",
  fork_mouse_enter: "Editor - Fork button mouse enter",
  fork_mouse_leave: "Editor - Fork button mouse leave",
  topbar_open_command_palette:
    "Editor - Topbar - Open command palette from dropdown",
  anon_user: "Anonymous access",

  reconnecting: "Editor - Reconnecting",
  manual_reconnect: "Editor - Manual reconnect",
  error_page: "Editor - Error Page",
  invite_collaborator_email: "Invite Collaborator (Email)",
  invite_collaborator_username: "Add Collaborator",
  copy_sandbox_url: "Share - 'Copy Sandbox URL' Clicked",
  reset_branch_to_remote: "Editor - Reset branch to remote",
  update_sandbox_title: "Sandbox - Update Title",
  update_sandbox_privacy: "Sandbox - Update Privacy",
  update_sandbox_template_icon: "Template - Update Icon",

  pitcher_late_pong: "Pitcher - Late pong response",

  click_upgrade_vm_specs: "Editor - Click Upgrade VM Specs",
  apply_new_vm_specs: "Editor - Apply New VM Specs",
  show_resource_alert: "Editor - Show VM Usage Alert",

  stripe_trial_payment_reminder_seen:
    "Stripe banner - active trial seen payment reminder",
  stripe_trial_payment_reminder_dismissed:
    "Stripe banner - active trial dismiss payment reminder",
  stripe_trial_payment_reminder_add_info:
    "Stripe banner - active trial add payment details",
  embed_open_editor: "Embed - Open Editor",

  visibility: "Visibility",

  download: "Editor - Download",

  review_submit: "Editor - Review - Submit",
  review_comment: "Editor - Review - Comment",
  review_comment_open: "Editor - Review - Open comment",
  review_comment_close: "Editor - Review - Close comment",
  review_comment_github: "Editor - Review - Open comment on GitHub",
  review_comment_pending: "Editor - Review - Create pending comment",
  review_comment_reply: "Editor - Review - Reply to comment",

  change_theme: "Editor - Change theme",

  dependency_manager_install: "Editor - Dependency Manager - Install",
  dependency_manager_search: "Editor - Dependency Manager - Search",
  dependency_manager_uninstall: "Editor - Dependency Manager - Uninstall",
  dependency_manager_update: "Editor - Dependency Manager - Update",

  debug_devtools_open: "Editor - Debug devtool - Open",
  debug_devtools_close: "Editor - Debug devtool - Close",

  env_setup_devtool_open: "Editor - Setup devtool - Open",
  env_setup_devtool_documentation: "Editor - Setup devtool - Documentation",

  devcontainer_autodetect: "Editor - Devcontainer - Autodetect",
  devcontainer_custom: "Editor - Devcontainer - Custom",
  devcontainer_skip: "Editor - Devcontainer - Skip",
  devcontainer_select_template: "Editor - Devcontainer - Select template",
  devcontainer_add_feature: "Editor - Devcontainer - Add feature",
  devcontainer_remove_feature: "Editor - Devcontainer - Remove feature",

  env_setup_devtool_githubapp: "Editor - Setup devtool - GitHub App",
  env_setup_devtool_set_envvars: "Editor - Setup devtool - Set env vars",
  env_setup_devtool_remove_envvars: "Editor - Setup devtool - Remove env vars",
  env_setup_devtool_prefilled_envvars:
    "Editor - Setup devtool - Prefilled env vars",

  env_setup_devtool_add_setup_task: "Editor - Setup devtool - Add setup task",
  env_setup_devtool_remove_setup_task:
    "Editor - Setup devtool - Remove setup task",
  env_setup_devtool_prefilled_setup_task:
    "Editor - Setup devtool - Prefilled setup task",
  env_setup_devtool_reorder_setup_task:
    "Editor - Setup devtool - Reorder setup task",
  env_setup_devtool_error: "Editor - Setup devtool - Error",

  env_setup_summary_navigate: "Editor - Setup devtool - Summary - Navigate",
  env_setup_summary_submit: "Editor - Setup devtool - Summary - Submit",
  env_setup_summary_skip: "Editor - Setup devtool - Summary - Skip onboarding",

  env_setup_navigate_next: "Editor - Setup devtool - Navigate Next",
  env_setup_navigate_prev: "Editor - Setup devtool - Navigate Prev",

  frozen_message: "Editor - Show Frozen Message",

  embed_open_modal: "Embed - Open Modal",
  embed_set_option: "Embed - Set option",

  embed_share_options: "Embed - Share options",

  editor_loaded: "Editor - Loaded",
  convert_to_devbox_open: "Editor - Convert to devbox - Open modal",
  convert_to_devbox_submit: "Editor - Convert to devbox - Submit",
  codeium_closed_modal: "Codeium - Closed modal",
  codeium_enable: "Codeium - Enabled",
  codeium_disable: "Codeium - Disabled",
  codeium_opened_modal: "Codeium - Opened modal",
  codeium_insert_suggestion: "Codeium - Insert suggestion",
  codeium_reach_free_sandbox_limit: "Codeium - Reach free sandbox limit",

  setup_task_failed: "Setup task - failed",
  pitcher_initialized: "Pitcher initialized",

  live_session_started: "Live session - Started",
  live_session_stopped: "Live session - Stopped",
  live_session_guest_joined: "Live session - Guest joined",
  live_session_default_permission_changed:
    "Live session - Default permission changed",
  live_session_guest_permission_changed:
    "Live session - Guest permission changed",

  share_private_draft_open_drafts: "Share - Private draft - Click Open Drafts",
  share_private_draft_move_out_of_drafts:
    "Share - Private draft - Click Move out of Drafts",
  restricted_sandbox_upgrade_to_pro:
    "Restricted sandbox banner - Click upgrade",
  restricted_sandbox_contact_us: "Restricted sandbox banner - Click contact",

  public_restricted_draft_display: "Public draft banner - Displayed",
  public_restricted_draft_made_public:
    "Public draft banner - Click make it private",

  fork_sandbox_limit: "Fork - Sandbox limit",
  seamless_fork_sandbox_limit: "Seamless fork - Sandbox limit",
  procted_branch_toggle: "Seettings - toggle protected branch",
  codeium_upgrade_to_pro: "Codeium - upgrade from free",

  export_to_github: "Export to GitHub Clicked",

  error_request_access: "Error Page - Request Workspace Access",
  error_workspace_access_requested: "Error Page - Workspace Access Requested",

  breadcrumb_hover_folder: "Editor - hover on folder breadcumb",
  breadcrumb_open_folder: "Editor - open folder breadcrumb",
  breadcrumb_hover_repo: "Editor - hover on repo breadcumb",
  breadcrumb_open_repo: "Editor - open repo breadcrumb",
  breadcrumb_hover_workspace: "Editor - hover on workspace breadcumb",
  breadcrumb_open_workspace: "Editor - open workspace breadcrumb",

  install_missing_dep: "Editor - Install missing browser dependencies",

  join_eligible_workspace: "Editor - Joining eligible workspace",
  eligible_workspace_found: "Editor - Eligible workspace found",

  show_changelog_note: "Editor - Show Changelog Notification",
  open_changelog: "Editor - Open Changelog",
  set_vscode_web: "Editor - Set VSCode Web experiment",
  set_vscode_server: "Editor - Set VSCode Server experiment",
  set_vscode_marketplace: "Editor - Set VSCode Marketplace experiment",

  test_open_from_preview: "Editor - Test - Open from preview",
  test_open_from_configfile: "Editor - Test - Open from config file",
  test_run_test: "Editor - Test - Run test",
  test_toggle_watch: "Editor - Test - Toggle watch",
  test_toggle_description: "Editor - Test - Toggle description",
  test_run_only_this_file: "Editor - Test - Run only this file",
};

type MetricLogger = (metric: unknown) => Promise<unknown>;
export const buildMetricAndTrack =
  (metricLogger: MetricLogger) =>
  <T extends AnalyticsEventParameters>(...params: T) => {
    const metricName = MAP_EVENT_KEY_TO_METRIC_NAME[params[0]];
    const additionalInfo = params[1] ?? {};
    const metric = {
      ...additionalInfo,
      metric: metricName,
    };

    return metricLogger(metric);
  };

export const useAnalytics = () => {
  const { amplitude } = useEnvironmentInterface() ?? {
    amplitude: { track: (_: unknown) => ({}) }, // noop because useEnvironment is null on startup.
  };

  const track = useMemo(
    () => buildMetricAndTrack(amplitude.track),
    [amplitude.track],
  );

  return track;
};

export const useTrackPageView = () => {
  const track = useAnalytics();
  // Using the document instead of the router because `pathname`
  // is the defined path (eg: /[owner]/[repo]/...) and the
  // alternative way to get the actual path (asPath) includes
  // the query.
  const pathname =
    typeof document !== "undefined" ? document.location.pathname : undefined;
  const previousPathname = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (pathname && previousPathname.current !== pathname) {
      track("pageview", { path: pathname });
      previousPathname.current = pathname;
    }
  }, [pathname, track]);
};
